import { getHeaders } from '@utility/helperFunction';
export const CALL_SLOT_ASSIGN_REQUESTED = 'cubby/CALL_SLOT_ASSIGN_REQUESTED';
export const CALL_SLOT_ASSIGN_SUCCESS = 'cubby/CALL_SLOT_ASSIGN_SUCCESS';
export const CALL_SLOT_ASSIGN_FAILURE = 'cubby/CALL_SLOT_ASSIGN_FAILURE';

export const UPDATE_ORDER_STATUS_REQUESTED = 'orderstatus/UPDATE_ORDER_STATUS_REQUESTED';
export const UPDATE_ORDER_STATUS_SUCCESS = 'orderstatus/UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'orderstatus/UPDATE_ORDER_STATUS_FAILURE';

export const ORDER_PICKED_UP_REQUESTED = 'orderstatus/ORDER_PICKED_UP_REQUESTED';
export const ORDER_PICKED_UP_SUCCESS = 'orderstatus/ORDER_PICKED_UP_SUCCESS';
export const ORDER_PICKED_UP_FAILURE = 'orderstatus/ORDER_PICKED_UP_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CALL_SLOT_ASSIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case CALL_SLOT_ASSIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CALL_SLOT_ASSIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_ORDER_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ORDER_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case ORDER_PICKED_UP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ORDER_PICKED_UP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ORDER_PICKED_UP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

export const cubbySlotAssignApi = data => {
  const headers = getHeaders();  
  return {
    types: [
      CALL_SLOT_ASSIGN_REQUESTED,
      CALL_SLOT_ASSIGN_SUCCESS,
      CALL_SLOT_ASSIGN_FAILURE,
    ],
    promise: client =>
      client.post('cubbyAssign', {
        data,
        headers,
      }),
  };
};

export const updateOrderStatus = data => {
  const headers = getHeaders();
  return {
    types: [
      UPDATE_ORDER_STATUS_REQUESTED,
      UPDATE_ORDER_STATUS_SUCCESS,
      UPDATE_ORDER_STATUS_FAILURE,
    ],
    promise: client => client.post('updateOrderStatus', { data, headers }),
  };
};

export const orderPickedUp = data => {
  const headers = getHeaders();
  return {
    types: [
      ORDER_PICKED_UP_REQUESTED,
      ORDER_PICKED_UP_SUCCESS,
      ORDER_PICKED_UP_FAILURE,
    ],
    promise: client => client.post('orderPickedUp', { data, headers }),
  };
};
