import React, { useRef, useState } from 'react';

/**
 * Base component for a touchable element that ensures interactions on both mobile and desktop devices
 * trigger `onClick` only when the touch or mouse click ends within the element.
 *
 * Benefits:
 * - Users can safely be touching another part of the screen and the touch/click will be handled properly.
 * - Users can hold their touch/click as long as they want and the touch/click will be handled properly.
 * - Users can decide not to trigger the element by sliding/dragging away from the element before releasing a touch/click.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - (Required) The function to execute when the element is tapped or clicked
 * @param {React.ElementType} props.component - (Required) The HTML element type (e.g., 'div', 'button', 'span')
 * @param {React.ReactNode} props.children - (Required) The child elements to be wrapped
 * @param {Object} [props.style] - Optional inline styles for the component
 * @param {string} [props.className] - Optional className for styling
 * @returns {JSX.Element} An interactive element with proper touch handling
 */
const Touchable = ({
  component: Component,
  onClick,
  children,
  style,
  className,
  ...props
}) => {
  const elementRef = useRef(null);
  const [isTouchEvent, setIsTouchEvent] = useState(false);

  /**
   * Checks if the event occurred within the element's boundaries
   * @param {Event} event - The touch or mouse event
   * @returns {boolean} - Whether the event was within the element's boundaries
   */
  const isEventInsideElement = (event) => {
    if (!elementRef.current) return false;

    const { left, top, right, bottom } = elementRef.current.getBoundingClientRect();
    const { clientX, clientY } = event.type.startsWith('touch') ? event.changedTouches[0] : event;

    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
  };

  /**
   * Handles the touch end event and ensures the touch ended within the element's boundaries.
   */
  const handleTouchEnd = (event) => {
    if (!onClick || !isEventInsideElement(event) || props.disabled) return;
    setIsTouchEvent(true); // Set the flag to indicate that a touch event occurred
    onClick(event);
  };

  /**
   * Fallback for non-touch devices (mouse events)
   */
  const handleMouseUp = (event) => {
    if (!onClick || isTouchEvent || props.disabled) {
      setIsTouchEvent(false); // Reset flag for future interactions
      return;
    }
    if (isEventInsideElement(event)) {
      onClick(event);
    }
  };

  return (
    <Component
      ref={elementRef}
      onTouchEnd={handleTouchEnd}
      onMouseUp={handleMouseUp}
      style={style}
      className={className}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Touchable;
