import paidIcon from '../assets/images/Fireboard/paid.png';
import unpaidIcon from '../assets/images/Fireboard/unpaid.png';
import preauthIcon from '../assets/images/Fireboard/finances.png';
import preAuthPoundIcon from '../assets/images/Fireboard/pound_red.png';
import partialPaidPreAuthDollarIcon from '../assets/images/Fireboard/auth_dollar.png';
import partialPaidIcon from '../assets/images/Fireboard/partialPaid.png';
import partialPaidPreAuthPoundIcon from '../assets/images/Fireboard/auth_pound.png';
import { ReactComponent as Tab } from '../assets/images/Fireboard/MODES/Tablet.svg';
import { ReactComponent as Table } from '../assets/images/Fireboard/MODES/Table_Order.svg';
import { ReactComponent as Kiosk } from '../assets/images/Fireboard/MODES/Kiosk2.svg';
import { ReactComponent as CallIn } from '../assets/images/Fireboard/MODES/CallIn_Order.svg';
import { ReactComponent as Online } from '../assets/images/Fireboard/MODES/Online_Order.svg';
import { ReactComponent as SpeedOrder } from '../assets/images/Fireboard/MODES/Speed_Order.svg';
import { ReactComponent as WalkUp } from '../assets/images/Fireboard/MODES/walk_up.svg';
import { ReactComponent as Cubby } from '../assets/images/Fireboard/MODES/cubby_order.svg';

export const displayTime = (closeDate, time) => {
  let displayValue;
  const sentTime = new Date(time).getTime() / 1000;
  let closedTime;
  if (closeDate !== null) {
    closedTime = new Date(closeDate).getTime() / 1000;
  } else {
    // currentTime = Date.now() / 1000;
    closedTime = new Date(time).getTime() / 1000;
  }
  const timeElapsed = parseInt((closedTime - sentTime) / 60);
  if (timeElapsed < 120) {
    displayValue = `${timeElapsed}m`;
  } else {
    displayValue = '2h+';
  }
  return displayValue;
};

export const getTimeIn12HourClock = time => {
  if (!time) return;
  const splitValues = String(time).split(':');
  const hours = +splitValues[0];
  const session = hours >= 12 ? 'PM' : 'AM';
  let hrs = hours > 12 ? hours - 12 : hours;
  hrs = hrs === 0 ? 12 : hrs; // adding condition to handle 12 AM
  hrs = hrs < 10 ? `0${hrs}` : hrs;
  const mins = +splitValues[1] < 10 ? `0${+splitValues[1]}` : +splitValues[1];
  return `${hrs}:${mins} ${session}`;
};

export const getDeliverTo = check => {
  const { sourceType } = check || {};
  const order = check.orders && check.orders.length > 0 && check.orders[0];
  const vehicleInfo = order?.vehicleInfo || null;
  const checkInNote = check?.checkInNote || null;
  const checkName = check?.name || null;
  switch (sourceType) {
    case 0: // POS, DEFAULT
      let posName;
      if (checkInNote)
        posName = posName ? `${posName}\n${checkInNote}` : checkInNote;
      if (vehicleInfo)
        posName = posName ? `${posName}\n${vehicleInfo}` : vehicleInfo;
      if (checkName && checkName !== checkInNote)
        posName = posName ? `${posName}\n${checkName}` : checkName;

      if (check.tab) {
        return posName ? posName : '- -';
      } else {
        if (check.stationNumber && check.name)
          return `${check.stationNumber}\n${check.name}`;
        else if (check.stationNumber) return check.stationNumber;
        else if (check.name) return check.name;
        else return '- -';
      }

    case 1: // KIOSK
    case 5: // CURBSIDE
    case 6: // ONLINE
    case 7: // WALKUP
    case 8: // TEXTTOPAY
    case 9: // SCANTOPAY
      let name;
      if (checkInNote) name = name ? `${name}\n${checkInNote}` : checkInNote;
      if (vehicleInfo) name = name ? `${name}\n${vehicleInfo}` : vehicleInfo;
      if (checkName && checkName !== checkInNote)
        name = name ? `${name}\n${checkName}` : checkName;
      return name || '- -';

    default:
      let defaultName;
      if (checkInNote)
        defaultName = defaultName
          ? `${defaultName}\n${checkInNote}`
          : checkInNote;
      if (vehicleInfo)
        defaultName = defaultName
          ? `${defaultName}\n${vehicleInfo}`
          : vehicleInfo;
      if (checkName && checkName !== checkInNote)
        defaultName = defaultName ? `${defaultName}\n${checkName}` : checkName;

      if (check.sourceType === 0) {
        return defaultName || check.stationNumber || '- -';
      }
      return defaultName || '- -';
  }
};

// export const formattedPhoneNumber = (phoneNumber, iso2) => {
//   if (phoneNumber && iso2) {
//     return new AsYouType(iso2).input(phoneNumber);
//   }
//   return phoneNumber;
// }

export const getPaidStatusIcon = (check, currency = '$') => {
  let paidImg, preAuthImg;
  const { tickets } = check || {};
  if (Array.isArray(tickets) && tickets.length > 0) {
    for (
      let ticketIdx = 0, ticketLen = tickets.length;
      ticketIdx < ticketLen;
      ticketIdx++
    ) {
      const { ticketStatusId, preAuth } = tickets[ticketIdx] || {};
      if (
        ticketStatusId === 0 &&
        preAuth &&
        check.unpaidAmount !== 0 &&
        check.paidAmount > 0
      ) {
        preAuthImg =
          currency === '£'
            ? partialPaidPreAuthPoundIcon
            : partialPaidPreAuthDollarIcon;
        break;
      } else if (ticketStatusId === 0 && preAuth && check.unpaidAmount > 0) {
        preAuthImg = currency === '£' ? preAuthPoundIcon : preauthIcon;
        break;
      }
    }
  }
  if (check && check.unpaidAmount === 0 && check.paidAmount > 0) {
    paidImg = paidIcon;
  } else if (check && check.unpaidAmount !== 0 && check.paidAmount > 0) {
    paidImg = partialPaidIcon;
  } else {
    paidImg = unpaidIcon;
  }
  return { paidIcon: paidImg, preAuthIcon: preAuthImg };
};

export const getTimeInHrsMins = time => {
  const convertTime = time && String(time).includes('Z');
  let date;
  if (convertTime) date = new Date(time);
  else date = new Date(time + 'Z');
  const hours = date.getHours();
  const session = hours >= 12 ? 'PM' : 'AM';

  let hrs = hours > 12 ? hours - 12 : hours;
  hrs = hrs === 0 ? 12 : hrs; // adding condition to handle 12 AM
  hrs = hrs < 10 ? `0${hrs}` : hrs;
  const mins =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return !isNaN(hrs) && !isNaN(mins) ? `${hrs}:${mins} ${session}` : '--';
};

export const getCheckInTime = item => {
  if (item.checkInDate) {
    return getTimeInHrsMins(item.checkInDate);
  }
  return '- -';
};

export const getElapsedTime = check => {
  const currentTime = new Date().getTime();
  const dueTime = check.pickupDate || check.orderDate || check.openDate;
  var convertTime = dueTime && dueTime.includes('Z');
  let date;
  if (convertTime) date = new Date(dueTime);
  else date = new Date(dueTime + 'Z');
  const orderTime = new Date(date).getTime();
  return currentTime - orderTime;
};

export const getOrderNameFromOrderId = check => {
  const { orders } = check || {};
  const order = Array.isArray(orders) && orders.length > 0 ? orders[0] : {};

  let retVal = {};
  const { unpaidAmount, tickets } = check;
  switch (order.orderStatusId) {
    case 0: {
      retVal = {
        status:
          check.sourceType !== 0
            ? 'Pending'
            : unpaidAmount > 0 && tickets && tickets.length > 0
            ? 'Pending'
            : 'In Progress',
        color:
          check.sourceType !== 0 || (unpaidAmount > 0 && tickets?.length > 0)
            ? 'rgba(255, 158, 81, 0.30)'
            : 'rgba(214, 201, 81, 0.30)',
        border:
          check.sourceType !== 0 || (unpaidAmount > 0 && tickets?.length > 0)
            ? '1px solid #FF9E51'
            : '1px solid #D6C951',
      };
      break;
    }
    case 1: {
      retVal = {
        status: 'In Progress',
        color: 'rgba(214, 201, 81, 0.30)',
        border: '1px solid #D6C951',
      };
      break;
    }
    case 5: {
      const [order0] = check?.orders || [];
      const { pickUpStatus } = order0 || {};
      retVal = {
        status:
          pickUpStatus === 5
            ? 'Picked Up'
            : pickUpStatus === 3
            ? 'See Counter'
            : 'Complete',
        color: 'rgba(46, 125, 50, 0.30)',
        border: '1px solid #63A770',
      };
      break;
    }
    case 8: {
      retVal = {
        status: 'cancelOrderText',
        border: '1px solid #FF6C6E',
        color: rgba(255, 108, 110, 0.3),
      };
      break;
    }
    case 9:
      retVal = {
        status: 'Error',
        border: '1px solid #FF6C6E',
        color: rgba(255, 108, 110, 0.3),
      };
      break;
    case 10:
      retVal = {
        status: 'orderAbandoned',
        border: '1px solid #FF6C6E',
        color: rgba(255, 108, 110, 0.3),
      };
      break;
    default:
      retVal = {};
  }
  return retVal;
};

export const getCheckSourceIcon = (
  sourceType,
  delivery,
  isMarketOrder,
  isCatering,
  isTab
) => {
  if (isMarketOrder) {
    return isCatering && delivery
      ? cateringDeliveryIcon
      : isCatering
      ? cateringIcon
      : delivery
      ? marketOrderDelivery
      : marketOrderOnline;
  }
  switch (sourceType) {
    case 0: // POS, DEFAULT
      return isTab === null || isTab === undefined ? (
        'pos'
      ) : isTab ? (
        <Tab />
      ) : (
        <Table />
      );
    case 1: //KIOSK
      return <Kiosk />;
    case 5: // CURBSIDE
      return <Cubby />;
    case 6: // ONLINE
      return delivery ? <Online /> : <Online />;
    case 7: // WALKUP
      return <WalkUp />;
    case 8: // TEXTTOPAY
      return <CallIn />;
    case 9: // SCANTOPAY
      return 'scanToPay';
    case 10: //Call Center Order
      return 'callCenter';
    case 11:
      return <SpeedOrder />;
    default:
      return '';
  }
};

export const getModeIconBgColor = (
  sourceType,
  delivery,
  isMarketOrder,
  isCatering,
  isTab
) => {
  if (isMarketOrder) {
    return isCatering && delivery
      ? cateringDeliveryIcon
      : isCatering
      ? cateringIcon
      : delivery
      ? marketOrderDelivery
      : marketOrderOnline;
  }
  switch (sourceType) {
    case 0: // POS, DEFAULT
      return isTab === null || isTab === undefined
        ? 'pos'
        : isTab
        ? 'rgba(142, 68, 173, 0.5)'
        : 'rgba(0, 137, 123, 0.5)';
    case 1: //KIOSK
      return 'rgba(33, 150, 243, 0.5)';
    case 5: // CURBSIDE
      return 'rgba(41, 231, 255, 0.5)';
    case 6: // ONLINE
      return delivery ? 'rgba(63, 81, 181, 0.5)' : 'rgba(63, 81, 181, 0.5)';
    case 7: // WALKUP
      return 'rgba(233, 30, 99, 0.5)';
    case 8: // TEXTTOPAY
      return 'rgba(255, 152, 0, 0.5)';
    case 9: // SCANTOPAY
      return 'scanToPay';
    case 10: //Call Center Order
      return 'callCenter';
    case 11:
      return 'rgba(255, 87, 34, 0.5)';
    default:
      return '';
  }
};

export const getPaymentType = type => {
  let paymentType;
  switch (type) {
    case 0:
      paymentType = 'CASH';
      break;
    case 1:
      paymentType = 'CREDIT CARD';
      break;
    case 2:
      paymentType = 'APPLE PAY';
      break;
    case 3:
      paymentType = 'GOOGLE PAY';
      break;
    case 4:
      paymentType = 'SAMSUNG PAY';
      break;
    case 5:
      paymentType = 'GIFT CARD';
      break;
    case 6:
      paymentType = 'PAYPAL';
      break;
    case 7:
      paymentType = 'STRIPE';
      break;
    case 8:
      paymentType = 'TOAST CC';
      break;
  }
  return paymentType;
};

export const getOrderTypeFromSourceType = type => {
  switch (type) {
    case 5:
      return 'Curbside';
    case 8:
      return 'Call in';
    case 1:
      return 'Kiosk';
    case 9:
      return 'Scan to pay';
    case 0:
      return 'POS';
    case 6:
      return 'Online';
    case 7:
      return 'Walk up';
    default:
      return '';
  }
};

export const getSourceTypeNameFromSourceType = (type, isTab) => {
  switch (type) {
    case 5:
      return 'Curbside';
    case 8:
      return 'Call in';
    case 1:
      return 'Kiosk';
    case 9:
      return 'Scan to pay';
    case 0:
      return isTab ? 'Tab' : 'Table';
    case 6:
      return 'Online';
    case 7:
      return 'Walk up';
    case 10:
      return 'Call Center';
    default:
      return '';
  }
};

export const getElapsedTimeInMs = dueDate => {
  if (!dueDate) return 0;
  const currentTime = new Date().getTime();
  let date;
  if (dueDate && dueDate.includes('Z')) date = new Date(dueDate);
  else date = new Date(dueDate + 'Z');
  const dueTime = new Date(date).getTime();

  return currentTime - dueTime;
};

export const getCubbySlotData = (storeCubby, checks) => {
  console.log({ storeCubby, checks });
  const checksMap = new Map();
  checks?.forEach(check => {
    const [order0] = check?.orders || [];
    const { storeCubbySlotId } = order0 || {};
    if (storeCubbySlotId) {
      checksMap.set(storeCubbySlotId, check);
    }
  });

  const { rowCount, columnCount, slots } = storeCubby || {};

  let slots2Darr = Array.from({ length: rowCount }, () =>
    Array.from({ length: columnCount }, () => null)
  );

  Array.isArray(slots) &&
    slots.length > 0 &&
    slots.forEach(slot => {
      let slotData = {
        ...slot,
        checkData: {},
      };
      const { id: slotId } = slot;
      if (slotId && checksMap.has(slotId)) {
        slotData = {
          ...slot,
          checkData: { ...checksMap.get(slotId) },
        };
      }
      slots2Darr[slot.rowNumber - 1][slot.columnNumber - 1] = slotData;
    });

  return slots2Darr;
};

export const getFormattedOpenOrCloseDate = data => {
  if (!data) return '---';
  const openOrDate = new Date(data);
  const currentDate = new Date();

  const isToday =
    openOrDate.getFullYear() === currentDate.getFullYear() &&
    openOrDate.getMonth() === currentDate.getMonth() &&
    openOrDate.getDate() === currentDate.getDate();

  if (isToday) {
    return openOrDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  } else {
    const datePart = openOrDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
    });
    const timePart = openOrDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${datePart} ${timePart}`;
  }
};

export const getRemainingTime = (check, currentTime = new Date()) => {
  const targetUTCTimeStr = check?.pickupDate;

  if (!targetUTCTimeStr) return { timer: '---', color: 'white' };

  // Parse the target UTC time
  const targetTime = check?.pickupDate?.includes('Z')
    ? new Date(check?.pickupDate)
    : new Date(check?.pickupDate + 'Z');
  const timeDifference = targetTime - currentTime;
  const isInFuture = timeDifference > 0;
  const absDifference = Math.abs(timeDifference);
  const seconds = Math.floor((absDifference / 1000) % 60);
  const minutes = Math.floor((absDifference / (1000 * 60)) % 60);
  const hours = Math.floor((absDifference / (1000 * 60 * 60)) % 24);

  const firstWarning = 6;
  const secondWarning = 4;
  const thirdWarning = 2;

  const convertToSecs = Math.floor(absDifference / 1000);
  const converToMinutes = Math.round(convertToSecs / 60);
  if (isInFuture) {
    if (converToMinutes < 1) {
      let ss = String(seconds).padStart(2, '0');
      return {
        timer: `00:${ss}`,
        color: 'red',
      };
    }

    if (convertToSecs > firstWarning*60) {
      if (convertToSecs > firstWarning * 60 && convertToSecs <= 3540) {
        let mm = String(minutes).padStart(2, '0');
        let ss = String(seconds).padStart(2, '0');

        return {
          timer: `${mm}:${ss}`,
          color: 'white',
        };
      } else if (convertToSecs > 3600) {
               let hh = hours > 4 ? `4h+` : `${hours}h+`;

               return {
                 timer: hh,
                 color: 'white',
               };
             }
    } else if (
      convertToSecs <= firstWarning * 60 &&
      convertToSecs > secondWarning * 60
    ) {
      let mm = String(minutes).padStart(2, '0');
      let ss = String(seconds).padStart(2, '0');

      return {
        timer: `${mm}:${ss}`,
        color: 'yellow',
      };
    } else if (
      convertToSecs <= secondWarning * 60 &&
      convertToSecs > thirdWarning * 60
    ) {
      let mm = String(minutes).padStart(2, '0');
      let ss = String(seconds).padStart(2, '0');

      return {
        timer: `${mm}:${ss}`,
        color: 'orange',
      };
    } else if (convertToSecs <= thirdWarning * 60) {
      let mm = String(minutes).padStart(2, '0');
      let ss = String(seconds).padStart(2, '0');

      return {
        timer: `${mm}:${ss}`,
        color: 'red',
      };
    }
  } else {
    if (converToMinutes < 1) {
      let ss = String(seconds).padStart(2, '0');
      return {
        timer: `00:${ss}`,
        color: 'red',
      };
    } else if (converToMinutes >= 1 && converToMinutes <= 59) {
      let mm = String(minutes).padStart(2, '0');
      let ss = String(seconds).padStart(2, '0');

      return {
        timer: `${mm}:${ss}`,
        color: 'red',
      };
    } else {
      let hh = hours > 4 ? '4h+' : `${hours}h+`;

      return {
        timer: hh,
        color: 'red',
      };
    }
  }
};

export default {
  displayTime,
  getTimeIn12HourClock,
  getDeliverTo,
  getPaidStatusIcon,
  getTimeInHrsMins,
  getCheckInTime,
  getElapsedTime,
  getOrderNameFromOrderId,
  getCheckSourceIcon,
  getPaymentType,
  getOrderTypeFromSourceType,
  getElapsedTimeInMs,
  getCubbySlotData,
  getModeIconBgColor,
  getFormattedOpenOrCloseDate,
  getRemainingTime,
};
