import React from 'react';
import Touchable from './touchable';
import { ReactComponent as Cross } from '@assets/images/cross.svg';
import { ReactComponent as BackArrow } from '@assets/images/arrowLeft.svg'



/**
 * Touchable button that ensures interactions on both mobile and desktop devices
 * trigger `onClick` only when the touch or mouse click ends within the <button> element.
 *
 * Benefits:
 * - Users can safely be touching another part of the screen and the touch/click will be handled properly.
 * - Users can hold their touch/click as long as they want and the touch/click will be handled properly.
 * - Users can decide not to trigger the element by sliding/dragging away from the element before releasing a touch/click.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - (Required) The function to execute when the element is tapped or clicked
 * @param {React.ReactNode} props.children - (Required) The child elements to be wrapped
 * @param {Object} [props.style] - Optional inline styles for the component
 * @param {string} [props.className] - Optional className for styling
 * @returns {JSX.Element} A button element with touch interaction support
 */
export const TouchableButton = (props) => <Touchable component="button" {...props} />;
/**
 * Touchable div that ensures interactions on both mobile and desktop devices
 * trigger `onClick` only when the touch or mouse click ends within the <div> element.
 *
 * Benefits:
 * - Users can safely be touching another part of the screen and the touch/click will be handled properly.
 * - Users can hold their touch/click as long as they want and the touch/click will be handled properly.
 * - Users can decide not to trigger the element by sliding/dragging away from the element before releasing a touch/click.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - (Required) The function to execute when the element is tapped or clicked
 * @param {React.ReactNode} props.children - (Required) The child elements to be wrapped
 * @param {Object} [props.style] - Optional inline styles for the component
 * @param {string} [props.className] - Optional className for styling
 * @returns {JSX.Element} A div element with touch interaction support
 */
export const TouchableDiv = (props) => <Touchable component="div" {...props} />;

/**
 * Touchable span that ensures interactions on both mobile and desktop devices
 * trigger `onClick` only when the touch or mouse click ends within the <span> element.
 *
 * Benefits:
 * - Users can safely be touching another part of the screen and the touch/click will be handled properly.
 * - Users can hold their touch/click as long as they want and the touch/click will be handled properly.
 * - Users can decide not to trigger the element by sliding/dragging away from the element before releasing a touch/click.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - (Required) The function to execute when the element is tapped or clicked
 * @param {React.ReactNode} props.children - (Required) The child elements to be wrapped
 * @param {Object} [props.style] - Optional inline styles for the component
 * @param {string} [props.className] - Optional className for styling
 * @returns {JSX.Element} A span element with touch interaction support
 */
export const TouchableSpan = (props) => <Touchable component="span" {...props} />;

/**
 * Touchable span that ensures interactions on both mobile and desktop devices
 * trigger `onClick` only when the touch or mouse click ends within the <span> element.
 *
 * Benefits:
 * - Users can safely be touching another part of the screen and the touch/click will be handled properly.
 * - Users can hold their touch/click as long as they want and the touch/click will be handled properly.
 * - Users can decide not to trigger the element by sliding/dragging away from the element before releasing a touch/click.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - (Required) The function to execute when the element is tapped or clicked
 * @param {React.ReactNode} props.children - (Required) The child elements to be wrapped
 * @param {Object} [props.style] - Optional inline styles for the component
 * @param {string} [props.className] - Optional className for styling
 * @returns {JSX.Element} A span element with touch interaction support
 */
export const TouchableIcon = (props) => <Touchable component="i" {...props} />;

/**
 * Touchable span that ensures interactions on both mobile and desktop devices
 * trigger `onClick` only when the touch or mouse click ends within the <span> element.
 *
 * Benefits:
 * - Users can safely be touching another part of the screen and the touch/click will be handled properly.
 * - Users can hold their touch/click as long as they want and the touch/click will be handled properly.
 * - Users can decide not to trigger the element by sliding/dragging away from the element before releasing a touch/click.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - (Required) The function to execute when the element is tapped or clicked
 * @param {React.ReactNode} props.children - (Required) The child elements to be wrapped
 * @param {Object} [props.style] - Optional inline styles for the component
 * @param {string} [props.className] - Optional className for styling
 * @returns {JSX.Element} A span element with touch interaction support
 */
export const TouchableImage = (props) => <Touchable component="img" {...props} />;

export const TouchableCross = (props) => <Touchable component={Cross} {...props} />;

export const TouchableBack = (props) => <Touchable component={BackArrow} {...props} />;

