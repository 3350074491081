export default {
  otherViewTypes: [
    { id: 0, type: 'listView', name: 'List View' },
    { id: 1, type: 'tileView', name: 'Tile View' },
    { id: 2, type: 'cubbyManager', name: 'Cubby Manager' },
    { id: 3, type: 'orderStatusManger', name: 'Order Status Manager' },
  ],
  otherModeTypes: [
    { id: 0, type: 'DEFAULT', name: 'General Mode' },
    { id: 1, type: 'ON_PREMISE', name: 'On Premise' },
    { id: 2, type: 'OFF_PREMISE', name: 'Off premise' },
    { id: 4, type: 'OFF_PREMISE_VENUE', name: 'Off Premise Venue' },
    { id: 3, type: 'TABLE_DELIVERY', name: 'Table delivery' },
  ],
};
