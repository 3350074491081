import { arrayOperations, utills } from '@utility';
import { getObjectValue, modifySeatObject } from '@utility/utills';
import { getHeaders } from '@utility/helperFunction';
import moment from 'moment';
import _, {
  filter,
  // find
} from 'lodash';
import { flowType as deviceType } from '@utility/userInfo';
import store from '../store';

const GET_MENU_REQUESTED = 'orders/GET_MENU_REQUESTED';
const GET_MENU_SUCCESS = 'orders/GET_MENU_SUCCESS';
const GET_MENU_FAILURE = 'orders/GET_MENU_FAILURE';
const GET_MENU_FROM_SESSION = 'orders/GET_MENU_FROM_SESSION';

const GET_MULTI_STORE_MENU_REQUESTED = 'orders/GET_MULTI_STORE_MENU_REQUESTED';
const GET_MULTI_STORE_MENU_SUCCESS = 'orders/GET_MULTI_STORE_MENU_SUCCESS';
const GET_MULTI_STORE_MENU_FAILURE = 'orders/GET_MULTI_STORE_MENU_FAILURE';
const GET_MULTI_STORE_MENU_FROM_SESSION = 'orders/GET_MULTI_STORE_MENU_FROM_SESSION';

const GET_MENU_ITEMS_BY_SCREEN_REQUEST = 'orders/GET_MENU_ITEMS_BY_SCREEN_REQUEST';
const GET_MENU_ITEMS_BY_SCREEN_SUCCESS = 'orders/GET_MENU_ITEMS_BY_SCREEN_SUCCESS';

const PUSH_MODIFIERS_LEVEL_DATA_REQUEST = 'orders/PUSH_MODIFIERS_LEVEL_DATA_REQUEST';
const PUSH_MODIFIERS_LEVEL_DATA_SUCCESS = 'orders/PUSH_MODIFIERS_LEVEL_DATA_SUCCESS';

const ADD_TO_CART_REQUESTED = 'orders/ADD_TO_CART_REQUESTED';
const ADD_TO_CART_SUCCESS = 'orders/ADD_TO_CART_SUCCESS';

const CLEAR_CART_REQUESTED = 'orders/CLEAR_CART_REQUESTED';
const CLEAR_CART_SUCCESS = 'orders/CLEAR_CART_SUCCESS';

const SEARCH_ITEM_REQUEST = 'orders/SEARCH_ITEM_REQUEST';
const SEARCH_ITEM_SUCCESS = 'orders/SEARCH_ITEM_SUCCESS';

const EDIT_CART_ITEM_REQUESTED = 'orders/EDIT_CART_ITEM_REQUESTED';
const EDIT_CART_ITEM_SUCCESS = 'orders/EDIT_CART_ITEM_SUCCESS';

const REMOVE_UPSELL_ITEMS_REQUESTED = 'orders/REMOVE_UPSELL_ITEMS_REQUESTED';
const REMOVE_UPSELL_ITEMS_SUCCESS = 'orders/REMOVE_UPSELL_ITEMS_SUCCESS';

const PUSH_CART_ITEMS_REQUESTED = 'orders/PUSH_CART_ITEMS_REQUESTED';
const PUSH_CART_ITEMS_SUCCESS = 'orders/PUSH_CART_ITEMS_SUCCESS';

const PUSH_MODIFIER_REQUESTED = 'orders/PUSH_MODIFIER_REQUESTED';
const PUSH_MODIFIER_SUCCESS = 'orders/PUSH_MODIFIER_SUCCESS';

const APPEND_MODIFIER_SUCCESS = 'orders/APPEND_MODIFIER_SUCCESS';

const UPADTE_MODIFIER_PROMISE_SUCCESS = 'orders/UPADTE_MODIFIER_PROMISE_SUCCESS';

const PUSH_MODIFIER_COUNT_REQUESTED = 'orders/PUSH_MODIFIER_COUNT_REQUESTED';
const PUSH_MODIFIER_COUNT_SUCCESS = 'orders/PUSH_MODIFIER_COUNT_SUCCESS';

const PUSH_NESTED_MODIFIER_REQUESTED = 'orders/PUSH_NESTED_MODIFIER_REQUESTED';
const PUSH_NESTED_MODIFIER_SUCCESS = 'orders/PUSH_NESTED_MODIFIER_SUCCESS';

const PUSH_CART_ITEMS_INFO_REQUEST = 'orders/PUSH_CART_ITEMS_INFO_REQUEST';
const PUSH_CART_ITEMS_INFO_SUCCESS = 'orders/PUSH_CART_ITEMS_INFO_SUCCESS';

const CHANGE_ITEM_QUANTITY_REQUEST = 'orders/CHANGE_ITEM_QUANTITY_REQUEST';
const CHANGE_ITEM_QUANTITY_SUCCESS = 'orders/CHANGE_ITEM_QUANTITY_SUCCESS';

const REMOVE_ITEM_FROM_CART_REQUEST = 'orders/REMOVE_ITEM_FROM_CART_REQUEST';
const REMOVE_ITEM_FROM_CART_SUCCESS = 'orders/REMOVE_ITEM_FROM_CART_SUCCESS';

const TOGGLE_NESTED_MODIFIER_FLAG_REQUEST = 'orders/TOGGLE_NESTED_MODIFIER_FLAG_REQUEST';
const TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS = 'orders/TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS';

const PLACE_ORDER_REQUESTED = 'orders/PLACE_ORDER_REQUESTED';
const PLACE_ORDER_SUCCESS = 'orders/PLACE_ORDER_SUCCESS';
const PLACE_ORDER_FAILURE = 'orders/PLACE_ORDER_FAILURE';

const GET_STORE_PICKUPTIME_REQUESTED = 'orders/GET_STORE_PICKUPTIME_REQUESTED';
const GET_STORE_PICKUPTIME_SUCCESS = 'orders/GET_STORE_PICKUPTIME_SUCCESS';
const GET_STORE_PICKUPTIME_FAILURE = 'orders/GET_STORE_PICKUPTIME_FAILURE';

const GET_STORE_PICKUPTIMEBYDATE_REQUESTED = 'orders/GET_STORE_PICKUPTIMEBYDATE_REQUESTED';
const GET_STORE_PICKUPTIMEBYDATE_SUCCESS = 'orders/GET_STORE_PICKUPTIMEBYDATE_SUCCESS';
const GET_STORE_PICKUPTIMEBYDATE_FAILURE = 'orders/GET_STORE_PICKUPTIMEBYDATE_FAILURE';

const GET_STORE_DELIVERYTIME_REQUESTED = 'orders/GET_STORE_DELIVERYTIME_REQUESTED';
const GET_STORE_DELIVERYTIME_SUCCESS = 'orders/GET_STORE_DELIVERYTIME_SUCCESS';
const GET_STORE_DELIVERYTIME_FAILURE = 'orders/GET_STORE_DELIVERYTIME_FAILURE';

const GET_STORE_DELIVERYTIMEBYDATE_REQUESTED = 'orders/GET_STORE_DELIVERYTIMEBYDATE_REQUESTED';
const GET_STORE_DELIVERYTIMEBYDATE_SUCCESS = 'orders/GET_STORE_DELIVERYTIMEBYDATE_SUCCESS';
const GET_STORE_DELIVERYTIMEBYDATE_FAILURE = 'orders/GET_STORE_DELIVERYTIMEBYDATE_FAILURE';

const GET_STORE_PICKUPDELIVERYTIME_REQUESTED = 'orders/GET_STORE_PICKUPDELIVERYTIME_REQUESTED';
const GET_STORE_PICKUPDELIVERYTIME_SUCCESS = 'orders/GET_STORE_PICKUPDELIVERYTIME_SUCCESS';
const GET_STORE_PICKUPDELIVERYTIME_FAILURE = 'orders/GET_STORE_PICKUPDELIVERYTIME_FAILURE';

const UPSELL_REQUESTED = 'orders/UPSELL_REQUESTED';
const UPSELL_SUCCESS = 'orders/UPSELL_SUCCESS';
const UPSELL_FAILURE = 'orders/UPSELL_FAILURE';

const CHANGE_MENU_REQUEST = 'orders/CHANGE_MENU_REQUEST';
const CHANGE_MENU_SUCCESS = 'orders/CHANGE_MENU_SUCCESS';

const CLEAR_MENUS_REQUESTED = 'orders/CLEAR_MENUS_REQUESTED';
const CLEAR_MENUS_SUCCESS = 'orders/CLEAR_MENUS_SUCCESS';

const CLEAR_ORDER_SCREEN_SUCCESS = 'orders/CLEAR_ORDER_SCREEN_SUCCESS';

const REPLACE_CART_SUCCESS = 'orders/REPLACE_CART_SUCCESS';
const REPLACE_CART_REQUEST = 'orders/REPLACE_CART_REQUEST';

const UPADTE_MULTI_CART_REQUEST = 'orders/UPADTE_MULTI_CART_REQUEST';
const UPADTE_MULTI_CART_SUCCESS = 'orders/UPADTE_MULTI_CART_SUCCESS';

const REMOVE_MODIFIERS_DATA_REQUEST = 'orders/REMOVE_MODIFIERS_DATA_REQUEST';

const SET_MENU_INDEX = 'orders/SET_MENU_INDEX';

const GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED = 'orders/GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED';
const GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS = 'orders/GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS';
const GET_STORE_PICKUPTIME_DATE_BASED_FAILURE = 'orders/GET_STORE_PICKUPTIME_DATE_BASED_FAILURE';
const GET_OTHER_ORDERS_REQUEST = 'orders/GET_OTHER_ORDERS_REQUEST';
const GET_OTHER_ORDERS_SUCCESS = 'orders/GET_OTHER_ORDERS_SUCCESS';
const GET_OTHER_ORDERS_FAILURE = 'orders/GET_OTHER_ORDERS_FAILURE';

const ORDER_ITEM_REQUESTED = 'orders/ORDER_ITEM_REQUESTED';
const ORDER_ITEM_SUCCESS = 'orders/ORDER_ITEM_SUCCESS';
const ORDER_ITEM_FAILURE = 'orders/ORDER_ITEM_FAILURE';

const UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST = 'orders/UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST';
const UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS = 'orders/UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS';

const ADD_TO_FAVOURITE_REQUESTED = 'orders/ADD_TO_FAVOURITE_REQUESTED';
const ADD_TO_FAVOURITE_SUCCESS = 'orders/ADD_TO_FAVOURITE_SUCCESS';
const ADD_TO_FAVOURITE_FAILURE = 'orders/ADD_TO_FAVOURITE_FAILURE';

const REMOVE_FROM_FAVOURITE_REQUESTED = 'orders/REMOVE_FROM_FAVOURITE_REQUESTED';
const REMOVE_FROM_FAVOURITE_SUCCESS = 'orders/REMOVE_FROM_FAVOURITE_SUCCESS';
const REMOVE_FROM_FAVOURITE_FAILURE = 'orders/REMOVE_FROM_FAVOURITE_FAILURE';

const GET_PICKUP_DELIVERY_TIMES_REQUEST = 'orders/GET_PICKUP_DELIVERY_TIMES_REQUEST';
const GET_PICKUP_DELIVERY_TIMES_SUCCESS = 'orders/GET_PICKUP_DELIVERY_TIMES_SUCCESS';
const GET_PICKUP_DELIVERY_TIMES_FAILURE = 'orders/GET_PICKUP_DELIVERY_TIMES_FAILURE';

const ADD_TO_SEAT_SUCCESS = 'orders/ADD_TO_SEAT_SUCCESS';
const ADD_TO_SYNC_SUCCESS = 'orders/ADD_TO_SYNC_SUCCESS';

const GET_SEAT_SUCCESS = 'orders/GET_SEAT_SUCCESS'

const SET_PAGE_NO = 'orders/SET_PAGE_NO';

const SET_POSITION_NO = 'orders/SET_POSITION_NO';

const SET_ITEM_TO_CHECK = '/orders/SET_ITEM_TO_CHECK';

const CLEAR_SEAT_REQUESTED = 'orders/CLEAR_SEAT_REQUESTED';
const CLEAR_SEAT_SUCCESS = 'order/CLEAR_SEAT_SUCCESS';

const ADD_CHECK_REQUEST = 'orders/ADD_CHECK_REQUEST';
const ADD_CHECK_SUCCESS = 'orders/ADD_CHECK_SUCCESS';
const ADD_CHECK_FAILURE = 'orders/ADD_CHECK_FAILURE';

const UPDATE_CHECK_REQUEST = 'orders/UPDATE_CHECK_REQUEST';
const UPDATE_CHECK_SUCCESS = 'orders/UPDATE_CHECK_SUCCESS';
const UPDATE_CHECK_FAILURE = 'orders/UPDATE_CHECK_FAILURE';

const DELETE_ITEM_REQUEST = 'orders/DELETE_ITEM_REQUEST';
const DELETE_ITEM_SUCCESS = 'orders/DELETE_ITEM_SUCCESS';
const DELETE_ITEM_FAILURE = 'orders/DELETE_ITEM_FAILURE';

const SEND_CHECKS_REQUEST = 'orders/SEND_CHECKS_REQUEST';
const SEND_CHECKS_SUCCESS = 'orders/SEND_CHECKS_SUCCESS';
const SEND_CHECKS_FAILURE = 'orders/SEND_CHECKS_FAILURE';

const MOVE_CHECKS_REQUEST = 'orders/MOVE_CHECKS_REQUEST';
const MOVE_CHECKS_SUCCESS = 'orders/MOVE_CHECKS_SUCCESS';
const MOVE_CHECKS_FAILURE = 'orders/MOVE_CHECKS_FAILURE';

const DELETE_ALL_ITEM_REQUEST = 'orders/DELETE_ALL_ITEM_REQUEST';
const DELETE_ALL_ITEM_SUCCESS = 'orders/DELETE_ALL_ITEM_SUCCESS';
const DELETE_ALL_ITEM_FAILURE = 'orders/DELETE_ALL_ITEM_FAILURE';

const SEND_ALL_ITEM_REQUEST = 'orders/SEND_ALL_ITEM_REQUEST';
const SEND_ALL_ITEM_SUCCESS = 'orders/SEND_ALL_ITEM_SUCCESS';
const SEND_ALL_ITEM_FAILURE = 'orders/SEND_ALL_ITEM_FAILURE';

const SEND_SINGLE_ITEM_REQUEST = 'orders/SEND_SINGLE_ITEM_REQUEST';
const SEND_SINGLE_ITEM_SUCCESS = 'orders/SEND_SINGLE_ITEM_SUCCESS';
const SEND_SINGLE_ITEM_FAILURE = 'orders/SEND_SINGLE_ITEM_FAILURE';

const SEAT_CLEAR_REQUEST = 'orders/SEAT_CLEAR_REQUEST';
const SEAT_CLEAR_SUCCESS = 'orders/SEAT_CLEAR_SUCCESS';
const SEAT_CLEAR_FAILURE = 'orders/SEAT_CLEAR_FAILURE';

const CLEAR_POSITION_REQUESTED = 'orders/CLEAR_POSITION_REQUESTED';
const UPDATE_WITH_PAYMENT_STAGES = 'orders/UPDATE_WITH_PAYMENT_STAGES';
const REMOVE_WITH_PAYMENT_STAGES = 'orders/REMOVE_WITH_PAYMENT_STAGES';
const CLEAR_PAYMENT_CHECK = 'orders/CLEAR_PAYMENT_CHECK';
const SET_PAYMENT_CHECK = 'orders/SET_PAYMENT_CHECK';

const UPDATE_SEAT_INFO = 'orders/UPDATE_SEAT_INFO';
const UPDATE_CART_AFTER_NOTE = 'orders/UPDATE_CART_AFTER_NOTE';

const UPDATE_CHECK_NAME_REQUEST = 'orders/UPDATE_CHECK_NAME_REQUEST';
const UPDATE_CHECK_NAME_SUCCESS = 'orders/UPDATE_CHECK_NAME_SUCCESS';
const UPDATE_CHECK_NAME_FAILURE = 'orders/UPDATE_CHECK_NAME_FAILURE';

const SET_HIGHLIGHTED_CART_ITEMS = 'orders/SET_HIGHLIGHTED_CART_ITEMS';

const VOID_ITEM_REQUEST = 'orders/VOID_ITEM_REQUEST';
const VOID_ITEM_SUCCESS = 'orders/VOID_ITEM_SUCCESS';
const VOID_ITEM_FAILURE = 'orders/VOID_ITEM_FAILURE';

const VOID_CHECK_REQUEST = 'orders/VOID_CHECK_REQUEST';
const VOID_CHECK_SUCCESS = 'orders/VOID_CHECK_SUCCESS';
const VOID_CHECK_FAILURE = 'orders/VOID_CHECK_FAILURE';

const SET_MODIFIER_ID = 'orders/SET_MODIFIER_ID';

const CHECK_VOID_REQUEST = 'orders/CHECK_VOID_REQUEST';
const CHECK_VOID_SUCCESS = 'orders/CHECK_VOID_SUCCESS';
const CHECK_VOID_FAILURE = 'orders/CHECK_VOID_FAILURE';

const GET_UPSELL_FROM_SESSION = 'orders/GET_UPSELL_FROM_SESSION';

const SET_STARTOVER_BTN = 'orders/SET_STARTOVER_BTN';

const SET_CATEGORY_BTN = 'orders/SET_CATEGORY_BTN';

const SET_STARTOVER_POP_UP = 'orders/SET_STARTOVER_POP_UP';

const SET_DISABLE_FOOTER = 'orders/SET_DISABLE_FOOTER';

const SET_GLOBAL_MOD_STATUS = 'orders/SET_GLOBAL_MOD_STATUS';
const SET_SPEED_ORDER_STATUS = 'orders/SET_SPEED_ORDER_STATUS';
const SET_SENDMODE_ORDER_STATUS = 'orders/SET_SENDMODE_ORDER_STATUS';
const SET_SPEED_ORDER_NAME = 'orders/SET_SPEED_ORDER_NAME';


const SET_SELECTED_MODIFIER_PATH = 'orders/SET_SELECTED_MODIFIER_PATH';

const PUSH_GLOBAL_MODIFIERS_DATA = 'orders/PUSH_GLOBAL_MODIFIERS_DATA';

const CLEAR_GLOBAL_MODIFIERS_DATA = 'orders/CLEAR_GLOBAL_MODIFIERS_DATA';

const GLOBAL_MODIFIER_DATA_REQUESTED = 'orders/GLOBAL_MODIFIER_DATA_REQUESTED';
const GLOBAL_MODIFIER_DATA_SUCCESS = 'orders/GLOBAL_MODIFIER_DATA_SUCCESS';

const SET_GLOBAL_SELECTED_MODIFIER_ID = 'orders/SET_GLOBAL_SELECTED_MODIFIER_ID';

const EDIT_MODIFIERS = 'orders/EDIT_MODIFIERS';

const SET_COUNTRY_CODE = 'checks/SET_COUNTRY_CODE';

const APPLY_DISCOUNT_REQUESTED = 'orders/APPLY_DISCOUNT_REQUESTED';
const APPLY_DISCOUNT_SUCCESS = 'orders/APPLY_DISCOUNT_SUCCESS';
const APPLY_DISCOUNT_FAILURE = 'orders/APPLY_DISCOUNT_FAILURE';

const DELETE_DISCOUNT_REQUESTED = 'orders/DELETE_DISCOUNT_REQUESTED';
const DELETE_DISCOUNT_SUCCESS = 'orders/DELETE_DISCOUNT_SUCCESS';
const DELETE_DISCOUNT_FAILURE = 'orders/DELETE_DISCOUNT_FAILURE';

const POST_DISCOUNT_REQUESTED = 'orders/POST_DISCOUNT_REQUESTED';
const POST_DISCOUNT_SUCCESS = 'orders/POST_DISCOUNT_SUCCESS';
const POST_DISCOUNT_FAILURE = 'orders/POST_DISCOUNT_FAILURE';

const DELETE_ALL_LOCAL_ITEMS = 'orders/DELETE_ALL_LOCAL_ITEMS';
const UPDATE_SEARCH_STRING = 'orders/UPDATE_SEARCH_STRING';

const RENAME_SEAT_REQUEST = 'orders/RENAME_SEAT_REQUEST';
const RENAME_SEAT_SUCCESS = 'orders/RENAME_SEAT_SUCCESS';
const RENAME_SEAT_FAILURE = 'orders/RENAME_SEAT_FAILURE';

const OPEN_COUNTER_DETAILS_POPUP = 'orders/OPEN_COUNTER_DETAILS_POPUP';

const getModifierData = (stateData, payload) => {
  let dataToSend = [];
  if (Array.isArray(payload)) {
    dataToSend = payload;
  } else if (!stateData.includes(payload)) {
    dataToSend = [...stateData, payload];
  } else {
    dataToSend = stateData.filter((e) => !e.includes(payload));
  }
  return dataToSend;
};

const getPath = (data, dataToAppend) => {
  const arrayData = [...data];
  if (dataToAppend) {
    arrayData.push(dataToAppend);
  }
  return arrayData.join('|');
};
const addOrRemoveModifierData = (stateData, payload, isInc, removeLast) => {
  let dataToSend = [];
  if (isInc) {
    dataToSend = [...stateData, payload];
  } else if (removeLast) {
    const index = _.findLastIndex(stateData, (path) => path === payload);
    if (index !== -1) {
      dataToSend = [...stateData];
      _.pullAt(dataToSend, index);
      const isNestedModifer = filter(dataToSend, (d) => d === payload);
      if (isNestedModifer.length === 0) {
        dataToSend = filter(dataToSend, (d) => !d.startsWith(`${getPath([`${payload}`])}`));
      }
    }
  } else {
    const index = _.findIndex(stateData, (path) => path === payload);
    if (index !== -1) {
      dataToSend = [...stateData];
      _.pullAt(dataToSend, index);
      const isNestedModifer = filter(dataToSend, (d) => d === payload);
      if (isNestedModifer.length === 0) {
        dataToSend = filter(dataToSend, (d) => !d.startsWith(`${getPath([`${payload}`])}`));
      }
    }
  }
  return dataToSend;
};

const renderMenuItems = (result, menuIndex) => {
  let nextState = {};
  try {
    // favouriteScreen changes
    const favouriteMenuItems = _.filter(Object.values(result.menuItems), (d) => d.favorite).reduce((a, v) => { a.push(v.id); return a; }, []);
    const favouriteScreens = [];
    result.menus.forEach((menu) => {
      menu.screens.forEach((screen) => {
        favouriteScreens.push(..._.filter(screen.menuItemLinks, (d) => favouriteMenuItems.includes(d.menuItemId)));
      });
    });
    nextState.favouriteScreen = getObjectValue(() => result.menus[menuIndex].screens, []).length > 0 ? {
      ...result.menus[menuIndex].screens[0],
      id: 0,
      name: 'My favorites',
      menuItemLinks: favouriteScreens
    } : null;
    // favouriteScreen changes ends
    const allMenusHashTable = {};
    const allScreensList = [];
    let index = 1;
    console.log('menu items', result);
    result?.menus.forEach(menu => {
      menu?.screens.forEach(v => {
      index += 1;
      const groupObject = {};
      const items = [];
      allScreensList.push({
        ...v,
        menuTypeId: menu.id
      });
      v.menuItemLinks.forEach((link) => {
        items.push({
          ...link,
          menuTypeId: v.id,
          menuItem: result.menuItems.find((menu) => menu.id === link.menuItemId)
        });
        groupObject[link.groupName] = {
          groupName: link.groupName,
          items: items.filter((i) => i.groupName === link.groupName)
        };
      });
      allMenusHashTable[v.id] = {
        ...v,
        index,
        menuTypeId: menu.id,
        menuItems: Object.values(groupObject)
      };
    })});
    nextState = {
      ...result,
      response: undefined,
      // menus: _.sortBy(result.menus[state.menuIndex].screens, 'sortOrder'),
      menus: getObjectValue(() => result.menus[menuIndex].screens, []),
      firstScreenId: getObjectValue(() => result.menus[menuIndex].screens[0].id, []),
      menusList: result.menus,
      menuOptions: result.menus.map((menu, i) => ({ label: menu.name, value: i })),
      menusHashTable: arrayOperations.listToHashTable(getObjectValue(() => result.menus[menuIndex].screens, [])),
      menuItemsHashTable: arrayOperations.listToHashTable(result.menuItems),
      modifiersHashTable: arrayOperations.listToHashTable(result.modifiers),
      coursesHashTable: result.courses ? arrayOperations.listToHashTable(result.courses) : [],
      modifierGroupsHashTable: arrayOperations.listToHashTable(result.modifierGroups),
      allMenusHashTable: _.orderBy(Object.values(allMenusHashTable), [(m) => m.index], ['asc']),
      allScreensList
    };
    Object.assign(nextState.modifiersHashTable, arrayOperations.listToHashTable(result.menuItems));
    const menuCard = {};
    nextState.menus.forEach((menus) => {
      const returnObj = {};
      menus.menuItemLinks.forEach((item, indexMenu) => {
        returnObj[item.menuItemId] = {
          ...item,
          menuItem: nextState.menuItemsHashTable[item.menuItemId],
          menuTypeId: menus.id,
          index: indexMenu
        };
      });
      menuCard[menus.id] = returnObj;
    });
    nextState.menuCard = menuCard;
    // menu cardList
    const menuCardList = {};
    nextState.menusList.forEach((menuListItem) => {
      menuListItem.screens.forEach((menus) => {
        const returnObj = {};
        menus.menuItemLinks.forEach((item, indexMenu) => {
          returnObj[item.menuItemId] = {
            ...item,
            menuItem: nextState.menuItemsHashTable[item.menuItemId],
            menuTypeId: menus.id,
            index: indexMenu
          };
        });
        menuCardList[menus.id] = returnObj;
      });
    });
    nextState.menuCardList = menuCardList;
    const menusListHashTableTemp = {};
    // menusListHash
    nextState.menusList.forEach((menus) => {
      Object.assign(menusListHashTableTemp, arrayOperations.listToHashTable(menus.screens));
    });
    nextState.menusHashTable = menusListHashTableTemp;
    // menucardList
    const menuTypeId = Number(window.sessionStorage.getItem('menuTypeId'));
    const screen = menuTypeId ? nextState.menuCard[result.menus[menuIndex].screens.find((d) => d.id === menuTypeId)?.id] : nextState.menuCard[result.menus[menuIndex].screens[0].id];
    const screenMenuItemsList = _.orderBy(arrayOperations.converHashTableToArray(getObjectValue(() => screen, 0)), ['index'], ['asc']);
    nextState.screenMenuItemsList = screenMenuItemsList;
  } catch (e) {
    console.log(e);
  }
  return nextState;
};

const getInitialMenuData = () => {
  const menuIndex = Number(window.sessionStorage.getItem('menuIndex')) || 0;
  if (window.sessionStorage.getItem('menus')) {
    return { ...renderMenuItems(utills.decriptRespose(JSON.parse(window.sessionStorage.getItem('menus'))), menuIndex) };
  }
  return {};
};

const getInitialMutiMenuData = (storeId) => {
  const menuIndex = Number(window.sessionStorage.getItem('menuIndex')) || 0;
  if (window.localStorage.getItem('catchedMenus') && JSON.parse(window.localStorage.getItem('catchedMenus'))[storeId]) {
    return { ...renderMenuItems(utills.decriptRespose(JSON.parse(window.localStorage.getItem('catchedMenus'))[storeId]), menuIndex) };
  }
  return {};
};

const getAlphabets = () => {
  const result = [];
  for (let i = 65; i < 91; i += 1) {
    result.push(String.fromCharCode(i));
  }
  return result;
};

const alphabets = getAlphabets();
const getInitialData = (alphabetIndex, stateData) => {
  const currentAlphabet = alphabets[alphabetIndex];
  let initialData = {};
  const modList = _.filter(stateData?.modifierGroupsHashTable[stateData.globalSelectedModifierId]?.modifierLinks, (d) => stateData?.modifiersHashTable[d.modifierId]?.name.toLowerCase().startsWith(currentAlphabet?.toLowerCase()));
  const modListWithoutStartingAlphabet = _.filter(stateData?.modifierGroupsHashTable[stateData?.globalSelectedModifierId]?.modifierLinks, (d) => /^[^a-z]/i.test(stateData.modifiersHashTable[d.modifierId]?.name.toLowerCase()));
  if (modList.length > 0) {
    initialData = ({ items: modList, alphabet: currentAlphabet });
    return initialData;
  }
  if (alphabetIndex < alphabets.length - 1) {
    return getInitialData(alphabetIndex + 1, stateData);
  }
  if (modListWithoutStartingAlphabet.length > 0) {
    initialData = ({ items: modListWithoutStartingAlphabet, alphabet: 'others' });
    return initialData;
  }
  return initialData;
};

const getGlobalModifierLinkData = (stateData, isInitial, searchAlphabet, searchString) => {
  const modifierGroupList = [];
  if (isInitial) {
    const initialData = getInitialData(0, stateData);
    const modList = _.orderBy(initialData?.items, [(item) => stateData?.modifiersHashTable[item.modifierId]?.name.toLowerCase()], ['asc']);
    modifierGroupList.push({ items: modList, alphabetSelected: initialData?.alphabet });
  } else if (searchString) {
    let modList = _.filter(stateData?.modifierGroupsHashTable[stateData?.globalSelectedModifierId]?.modifierLinks, (d) => stateData?.modifiersHashTable[d.modifierId]?.name.toLowerCase().includes(searchString?.toLowerCase()));
    modList = _.orderBy(modList, [(item) => stateData?.modifiersHashTable[item.modifierId]?.name.toLowerCase()], ['asc']);
    modifierGroupList.push({ items: modList });
  } else if (searchAlphabet !== 'others') {
    let modList = _.filter(stateData?.modifierGroupsHashTable[stateData?.globalSelectedModifierId]?.modifierLinks, (d) => stateData?.modifiersHashTable[d.modifierId]?.name.toLowerCase().startsWith(searchAlphabet?.toLowerCase()));
    modList = _.orderBy(modList, [(item) => stateData?.modifiersHashTable[item.modifierId]?.name.toLowerCase()], ['asc']);
    modifierGroupList.push({ items: modList });
  } else if (searchAlphabet === 'others') {
    const modList = _.filter(stateData?.modifierGroupsHashTable[stateData?.globalSelectedModifierId]?.modifierLinks, (d) => /^[^a-z]/i.test(stateData.modifiersHashTable[d.modifierId].name.toLowerCase()));
    modifierGroupList.push({ items: modList });
  }
  return modifierGroupList;
};

const initialValues = {
  countrycode: getObjectValue(() => window.sessionStorage.getItem('countryCode'), 1),
  modifierEditData: null,
  startOverPopUp: false,
  isFooter: true,
  isHeight: false,
  startOverBtn: false,
  loading: false,
  loaded: true,
  error: false,
  modifierLoder: false,
  addToCartLoader: false,
  cart: JSON.parse(window.sessionStorage.getItem('cart')) || [],
  cartItemsInfo: _.groupBy(JSON.parse(window.sessionStorage.getItem('cart')) || [], (d) => d.menuItemId) || {},
  menus: [],
  firstScreenId: 0,
  menuItems: [],
  modifiers: [],
  modifierGroups: [],
  menusHashTable: {},
  menuItemsHashTable: {},
  modifiersHashTable: {},
  modifierGroupsHashTable: {},
  allMenusHashTable: {},
  allScreensList: [],
  menuCard: {},
  menuCardList: {},
  searchString: '',
  screenMenuItemsList: [],
  modifierLevelData: [],
  menusList: [],
  menuOptions: [],
  menuIndex: Number(window.sessionStorage.getItem('menuIndex')) || 0,
  ...getInitialMenuData(),
  // ...JSON.parse(window.sessionStorage.getItem('menus')),
  modifierData: [],
  isNestedModifier: false,
  pickUpTime: [],
  pickUpDeliveryTime: [],
  multiCartInfo: JSON.parse(window.localStorage.getItem('multiCartInfo')) || [],
  pickupTimeBasedOnDate: [],
  deliveryTime: [],
  upSellItems: JSON.parse(window.sessionStorage.getItem('upSell')) || [],
  multiStoreUpSell: JSON.parse(window.sessionStorage.getItem('multiStoreUpSell')) || [],
  placeOrderLoading: false,
  collapsedModifierArray: [],
  favouriteItems: JSON.parse(window.sessionStorage.getItem('favouriteItems')) || [],
  addToFavouriteLoader: false,
  isGetMenuLoaded: false,
  pickupDeliveryTimes: [],
  isUpsellComplete: false,
  seats: JSON.parse(window.sessionStorage.getItem('seat')) || null,
  syncData: JSON.parse(window.sessionStorage.getItem('syncData')) || null,
  position: JSON.parse(window.sessionStorage.getItem('position')) || {},
  paymentCheck: JSON.parse(window.sessionStorage.getItem('paymentCheck')) || null,
  highlightedCartItems: [],
  functionModifierId: null,
  globalModEnabled: true,
  speedOrderStatus: false,
  sendModeStatus: false,
  speedOrderName:null,
  selectedModifierPath: null,
  globalModifiersData: [],
  globalModifierLinksData: [],
  globalSelectedModifierId: null,
  coursesHashTable: {},
  openModalPopUp: false,
};

const clearData = {
  loading: false,
  loaded: true,
  error: false,
  modifierLoder: false,
  cart: [],
  cartItemsInfo: {},
  menus: [],
  menuItems: [],
  modifiers: [],
  modifierGroups: [],
  menusHashTable: {},
  menuItemsHashTable: {},
  modifiersHashTable: {},
  modifierGroupsHashTable: {},
  allMenusHashTable: {},
  allScreensList: [],
  menuCard: {},
  menuCardList: {},
  searchString: '',
  screenMenuItemsList: [],
  // modifierLevelData: [],
  menusList: [],
  menuOptions: [],
  menuIndex: Number(window.sessionStorage.getItem('menuIndex')) || 0,
  modifierData: [],
  isNestedModifier: false,
  pickUpTime: [],
  pickupTimeBasedOnDate: [],
  // multiCartInfo: []
  otherOrdersList: [],
  deliveryTime: [],
  placeOrderLoading: false,
  collapsedModifierArray: [],
  favouriteItems: [],
  addToFavouriteLoader: false,
  pickupDeliveryTimes: [],
  isUpsellComplete: false,
  pageNo: JSON.parse(window.sessionStorage.getItem('pageNo')) || 0,
  highlightedCartItems: [],
  functionModifierId: null,
  globalModEnabled: true,
  selectedModifierPath: null,
  globalModifiersData: [],
  globalModifierLinksData: [],
  globalSelectedModifierId: null,
  discounts: []
};

let nextState = {};
let screenMenuItemsList = [];
let menuCard = {};
let index = -1;
let cartItemsTemp = [];
let searchItemsList = [];
let filteredItemsList = {};
let upSellItems = [];
let catchedMenus = {};
let cartTempArray = [];
let cartItemIndex = -1;

export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_MENU_REQUESTED:
      return {
        ...state,
        cart: [],
        cartItemsInfo: {},
        menuLoading: true,
        loading: true,
        loaded: false
      };
    case GET_MENU_SUCCESS:
      nextState = renderMenuItems(action.result, state.menuIndex);
      window.sessionStorage.setItem('menus', JSON.stringify(action.result.response.compressedBytes));
      window.sessionStorage.setItem('dissmissableFooter', JSON.stringify([]));
      return {
        ...state,
        menuLoading: false,
        discounts: nextState.discounts,
        loading: false,
        screenMenuItemsList,
        isGetMenuLoaded: true,
        ...nextState
      };
    case GET_MENU_FROM_SESSION:
      return {
        ...state,
        ...getInitialMenuData(),
        // ...JSON.parse(window.sessionStorage.getItem('menus'))
      };
    case GET_MENU_FAILURE:
      return {
        ...state,
        menuLoading: false,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_REQUESTED:
      return {
        ...state,
        menuLoading: true,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_SUCCESS:
      catchedMenus = JSON.parse(window.localStorage.getItem('catchedMenus')) || {};
      catchedMenus[action.payload.storeId] = action.result.response.compressedBytes;
      window.localStorage.setItem('catchedMenus', JSON.stringify(catchedMenus));
      return {
        ...state,
        menuLoading: false,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_FAILURE:
      return {
        ...state,
        menuLoading: false,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_FROM_SESSION:
      nextState = getInitialMutiMenuData(action.payload.storeId);
      action.resolve();
      return {
        ...state,
        ...nextState,
      };
    case CHANGE_MENU_REQUEST:
      return {
        ...state,
        loading: false
      };
    case CHANGE_MENU_SUCCESS:
      nextState = {
        menus: state.menusList[action.payload].screens,
      };
      menuCard = {};
      nextState.menus.forEach((menus) => {
        const returnObj = {};
        menus.menuItemLinks.forEach((item, indexMenu) => {
          returnObj[item.menuItemId] = {
            ...item,
            menuItem: state.menuItemsHashTable[item.menuItemId],
            menuTypeId: menus.id,
            index: indexMenu
          };
        });
        menuCard[menus.id] = returnObj;
      });
      nextState.menuCard = menuCard;
      screenMenuItemsList = state.menusList[action.payload].screens.length > 0 && _.orderBy(arrayOperations.converHashTableToArray(nextState.menuCard[state.menusList[action.payload].screens[0].id]), ['index'], ['asc']);
      nextState.screenMenuItemsList = screenMenuItemsList;
      window.sessionStorage.setItem('menuIndex', action.payload);
      return {
        ...state,
        loading: false,
        screenMenuItemsList,
        menuIndex: Number(window.sessionStorage.getItem('menuIndex')) || 0,
        ...nextState
      };
    case GET_MENU_ITEMS_BY_SCREEN_REQUEST:
      return {
        ...state,
        loading: false
      };
    case UPDATE_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.payload
      };
    }
    case GET_MENU_ITEMS_BY_SCREEN_SUCCESS:
      let srtOrder = state.menuCardList[action.payload]
      const array = Object.values(srtOrder ? srtOrder : {}).sort((a, srtOrder) => a?.sortOrder - srtOrder?.sortOrder);
      return {
        ...state,
        screenMenuItemsList:array,
        loading: false
      };
    case SEARCH_ITEM_REQUEST:
      return {
        ...state,
        screenMenuItemsList: [],
        loading: true
      };
    case SEARCH_ITEM_SUCCESS: {
      searchItemsList = [];
      filteredItemsList = {};
      arrayOperations.converHashTableToArray(state.menuCardList).forEach((menuItemList) => {
        arrayOperations.converHashTableToArray(menuItemList).forEach((item) => {
          if (getObjectValue(() => item.menuItem.name.toLowerCase().includes(action.payload.toLowerCase()) || (item.menuItem.description || '').toLowerCase().includes(action.payload.toLowerCase()) || _.filter(item.menuItem.tags, (d) => d.name.toLowerCase().includes(action.payload.toLowerCase()) || d.shortName.toLowerCase().includes(action.payload.toLowerCase())).length > 0, false)) {
            searchItemsList.push(item);
          }
        });
      });
      arrayOperations.converHashTableToArray(state.allMenusHashTable).forEach((screen) => {
        screen.menuItems.forEach((menuItem) => {
          const items = [];
          menuItem.items.forEach((item) => {
            if (getObjectValue(() => item.menuItem.name.toLowerCase().includes(action.payload.toLowerCase()) || (item.menuItem.description || '').toLowerCase().includes(action.payload.toLowerCase()) || _.filter(item.menuItem.tags, (d) => d.name.toLowerCase().includes(action.payload.toLowerCase()) || d.shortName.toLowerCase().includes(action.payload.toLowerCase())).length > 0, false) && !item.linkedItem) {
              items.push(item);
              filteredItemsList[screen.id] = {
                ...screen,
                menuItems: [{
                  ...menuItem,
                  items
                }]
              };
            }
          });
        });
      });
      return {
        ...state,
        screenMenuItemsList: _.uniqBy(searchItemsList, 'menuItemId') || [],
        filteredItemsList: _.orderBy(Object.values(filteredItemsList), [(m) => m.id], ['asc']),
        loading: false
      };
    }
    case PUSH_MODIFIERS_LEVEL_DATA_REQUEST:
      return {
        ...state,
        loading: false
      };
    case PUSH_MODIFIERS_LEVEL_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        modifierLevelData: action.payload,
        // modifierData: action.condition ? state.modifierData : [] // need to work
      };
    }
    case REMOVE_MODIFIERS_DATA_REQUEST:
      return {
        ...state,
        loading: false,
        modifierData: _.filter(state.modifierData.filter((e) => e), (d) => !(action.payload.some((e) => d.includes(e)))),
        // modifierData: action.condition ? state.modifierData : [] // need to work
      };

    case  VOID_CHECK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VOID_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case VOID_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ADD_TO_CART_REQUESTED:
      return {
        ...state,
        addToCartLoader: true,
        // loading: true
      };
    case ADD_TO_CART_SUCCESS: {
      const reqCart = action.payload.length > 0 ? [...state.cart, ...action.payload] : [...state.cart, action.payload];
      window.sessionStorage.setItem('cart', JSON.stringify(filter(reqCart, (cartData) => Object.keys(cartData).length > 0)));
      action.resolve(reqCart);
      return {
        ...state,
        cart: reqCart,
        cartItemsInfo: _.groupBy(reqCart, (d) => d.menuItemId),
        addToCartLoader: false,
        highlightedCartItems: action.payload.length > 0 ? [...action.payload] : [action.payload]
        // loading: false
      };
    // case REMOVE_AND_ADD_TO_CART_REQUESTED:
    //   return {
    //     ...state,
    //     addToCartLoader: true,
    //     // loading: true
    //   };
    // case REMOVE_AND_ADD_TO_CART_SUCCESS:
    //   if (action.payload.length > 0) {
    //     const data = filter((action.payload), (d) => d.seats && find((d.seats), (s) => s.seatId === state.position.id));
    //     window.sessionStorage.setItem('cart', JSON.stringify(filter(data, (cartData) => Object.keys(cartData).length > 0)));
    //   } else {
    //     window.sessionStorage.setItem('cart', JSON.stringify(action.payload));
    //   }
    //   console.log('actiondata', action.payload);
    //   action.resolve();
    //   return {
    //     ...state,
    //     cart: action.payload.length > 0 ? filter((action.payload), (d) => d.seats && find((d.seats), (s) => s.seatId === state.position.id)) : action.payload,
    //     addToCartLoader: false,
    //     // loading: false
    //   };
    }
    case UPADTE_MULTI_CART_REQUEST:
      return {
        ...state,
        // loading: true,
        modifierLoder: true,
        multiCartInfo: action.payload
      };
    case UPADTE_MULTI_CART_SUCCESS:
      window.localStorage.setItem('multiCartInfo', JSON.stringify(action.payload));
      action.resolve();
      return {
        ...state,
        modifierLoder: false,
        // loading: false,
        multiCartInfo: action.payload
      };
    case REPLACE_CART_REQUEST:
      return {
        ...state,
        loading: true,
        // loading: true
      };
    case REPLACE_CART_SUCCESS:
      window.sessionStorage.setItem('cart', JSON.stringify(action.payload));
      action.resolve();
      return {
        ...state,
        cart: action.payload,
        cartItemsInfo: _.groupBy(action.payload, (d) => d.menuItemId),
        loading: false,
        // addToCartLoader: false
      };

    case CLEAR_CART_REQUESTED:
      return {
        ...state,
        // loading: true
      };
    case CLEAR_CART_SUCCESS:
      window.sessionStorage.removeItem('cart');
      action.resolve();
      return {
        ...state,
        cart: [],
        cartItemsInfo: {},
        loading: false
      };

    case CLEAR_SEAT_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case CLEAR_SEAT_SUCCESS:
      window.sessionStorage.removeItem('isAssisstanceCalled');
      window.sessionStorage.removeItem('seat');
      window.sessionStorage.removeItem('position');
      window.sessionStorage.removeItem('paymentCheck');
      window.sessionStorage.removeItem('checkName');
      window.sessionStorage.removeItem('countryCode');
      return {
        ...state,
        loading: false,
        seats: null,
        position: {}
      };

    case CLEAR_ORDER_SCREEN_SUCCESS:
      window.sessionStorage.removeItem('cart');
      window.sessionStorage.removeItem('multiCartInfo');
      window.localStorage.removeItem('catchedMenus');
      action.resolve();
      return {
        ...state,
        cart: [],
        cartItemsInfo: {},
        multiCartInfo: [],
        upSellItems: [],
        loading: false,
      };
    case CLEAR_MENUS_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case CLEAR_MENUS_SUCCESS:
      action.resolve();
      window.sessionStorage.removeItem('menus');
      window.sessionStorage.removeItem('menuTypeId');
      window.sessionStorage.removeItem('scrol');
      window.sessionStorage.removeItem('cart');
      window.sessionStorage.removeItem('upSell');
      return {
        ...state,
        ...clearData,
      };
    case EDIT_CART_ITEM_REQUESTED:
      return {
        ...state,
        loading: false
      };
    case EDIT_CART_ITEM_SUCCESS:
      if (action.payload.id) {
        index = _.findIndex(state.cart, (d) => d.id === action.payload.id);
      } else {
        index = _.findIndex(state.cart, (d) => d.internalId === action.payload.internalId);
      }
      cartItemsTemp = [...state.cart];
      cartItemsTemp[index] = action.payload;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartItemsTemp]));
      action.resolve(1);
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, (d) => d.menuItemId),
        loading: false
      };
    case CHANGE_ITEM_QUANTITY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case CHANGE_ITEM_QUANTITY_SUCCESS:
      index = _.findIndex(state.cart, (d) => d.internalId === action.payload.internalId);
      cartItemsTemp = [...state.cart];
      cartItemsTemp[index].quantity = action.payload.quantity;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartItemsTemp]));
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, (d) => d.menuItemId),
        // loading: false
      };
    case REMOVE_ITEM_FROM_CART_REQUEST:
      return {
        ...state,
        // loading: true
        addToCartLoader: false
      };
    case REMOVE_ITEM_FROM_CART_SUCCESS:
      cartItemsTemp = [...state.cart];
      if (action.payload[0] && action.payload[0].quantity > 0) {
        index = _.findIndex(state.cart, (d) => d.internalId === action.payload[0].internalId);
        if (action.payload[0].quantity === 1) {
          _.remove(cartItemsTemp, (d) => d.internalId === action.payload[0].internalId);
        } else {
          cartItemsTemp[index].quantity = action.payload[0].quantity - 1;
        }
      } else {
        _.remove(cartItemsTemp, (d) => d.internalId === action.payload);
      }
      window.sessionStorage.setItem('cart', JSON.stringify([...cartItemsTemp]));
      action.resolve();
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, (d) => d.menuItemId),
        // loading: false
        addToCartLoader: false
      };
    case REMOVE_UPSELL_ITEMS_REQUESTED:
      return {
        ...state,
        loading: false
      };
    case REMOVE_UPSELL_ITEMS_SUCCESS:
      cartItemsTemp = _.filter([...state.cart], (d) => d.upSellStatus !== false);
      window.sessionStorage.setItem('cart', JSON.stringify(cartItemsTemp));
      action.resolve();
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, (d) => d.menuItemId),
        loading: false
      };
    case PUSH_CART_ITEMS_REQUESTED:
      return {
        ...state,
        loading: false
      };
    case PUSH_CART_ITEMS_SUCCESS:
      cartItemsTemp = [...state.cart, ...action.payload];
      window.sessionStorage.setItem('cart', JSON.stringify(cartItemsTemp));
      action.resolve();
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, (d) => d.menuItemId),
        loading: false
      };
    case PUSH_MODIFIER_REQUESTED:
      return {
        ...state,
        modifierLoder: false
      };
    case PUSH_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        modifierData: getModifierData(state.modifierData.filter((e) => e), action.payload)
      };
    case APPEND_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        modifierData: [...state.modifierData, ...action.payload]
      };
    case UPADTE_MODIFIER_PROMISE_SUCCESS:
      action.resolve(1);
      return {
        ...state,
        modifierLoder: false,
        modifierData: action.payload ? [..._.filter(state.modifierData, (d) => !d.startsWith(action.path)), action.payload] : _.filter(state.modifierData, (d) => !d.startsWith(action.path))
      };
    case PUSH_MODIFIER_COUNT_REQUESTED:
      return {
        ...state,
        modifierLoder: false
      };
    case PUSH_MODIFIER_COUNT_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        modifierData: addOrRemoveModifierData(state.modifierData, action.payload, action.isInc, action.removeLast)
      };
    case PUSH_NESTED_MODIFIER_REQUESTED:
      return {
        ...state,
        modifierLoder: false
      };
    case PUSH_NESTED_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        modifierData: getModifierData(state.modifierData, action.payload)
      };
    case PUSH_CART_ITEMS_INFO_REQUEST:
      return {
        ...state,
        // cartItemsInfo: {}
      };
    case PUSH_CART_ITEMS_INFO_SUCCESS:
      return {
        ...state,
        cartItemsInfo: _.groupBy(state.cart, (d) => d.menuItemId)
      };
    case TOGGLE_NESTED_MODIFIER_FLAG_REQUEST:
      return {
        ...state,
        loading: false
      };
    case TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS:
      return {
        ...state,
        loading: false,
        isNestedModifier: action.payload
      };
    case PLACE_ORDER_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case RENAME_SEAT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RENAME_SEAT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case RENAME_SEAT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_PICKUPTIME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpTime: action.result.pickupTimes,
      };
    case GET_STORE_PICKUPTIME_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_PICKUPTIMEBYDATE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPTIMEBYDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpTime: action.result.pickupTimes,
      };
    case GET_STORE_PICKUPTIMEBYDATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_DELIVERYTIME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_DELIVERYTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryTime: action.result,
      };
    case GET_STORE_DELIVERYTIME_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_DELIVERYTIMEBYDATE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_DELIVERYTIMEBYDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryTime: action.result,
      };
    case GET_STORE_DELIVERYTIMEBYDATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_PICKUPDELIVERYTIME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPDELIVERYTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpDeliveryTime: action.result,
      };
    case GET_STORE_PICKUPDELIVERYTIME_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_MENU_INDEX:
      window.sessionStorage.setItem('menuIndex', action.payload);
      return {
        ...state,
        menuIndex: Number(window.sessionStorage.getItem('menuIndex'))
      };
    case GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS:
      return {
        ...state,
        loading: false,
        pickupTimeBasedOnDate: action.result,
      };
    case GET_STORE_PICKUPTIME_DATE_BASED_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_OTHER_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OTHER_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        otherOrdersList: action.result,
      };
    }
    case GET_OTHER_ORDERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPSELL_REQUESTED: {
      return {
        ...state,
        isUpsellLoading: true
      };
    }
    case UPSELL_SUCCESS: {
      upSellItems = action.result.items.map((item) => ({
        ...item,
        menuItemName: getObjectValue(() => state.menuItemsHashTable[item.menuItemId].name, ''),
        price: getObjectValue(() => _.find(state.menusHashTable[item.menuScreenId].menuItemLinks, (d) => d.menuItemId === item.menuItemId).price, 0),
      }));
      if (action.payload.consolidatePayment) {
        window.sessionStorage.setItem('multiStoreUpSell', JSON.stringify(_.uniqBy(upSellItems, 'menuItemId')));
      }
      window.sessionStorage.setItem('upSell', JSON.stringify(_.uniqBy(upSellItems, 'menuItemId')));
      return {
        ...state,
        upSellItems: _.uniqBy(upSellItems, 'menuItemId') || [],
        multiStoreUpSell: action.payload.consolidatePayment ? _.uniqBy(upSellItems, 'menuItemId') : [],
        isUpsellLoading: false
      };
    }
    case ORDER_ITEM_REQUESTED:
      return {
        ...state,
        loading: true,
        placeOrderLoading: true
      };
    case ORDER_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        placeOrderLoading: false
      };
    case ORDER_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        placeOrderLoading: false
      };
    case UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST:
      return {
        ...state,
      };
    case UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS:
      return {
        ...state,
        collapsedModifierArray: action.payload,
      };
    case ADD_TO_FAVOURITE_REQUESTED:
      return {
        ...state,
        addToFavouriteLoader: true
      };
    case ADD_TO_FAVOURITE_SUCCESS:
      cartTempArray = [...state.cart];
      cartItemIndex = _.findIndex(cartTempArray, (d) => d.menuItemId === action.payload.menuItemId);
      cartTempArray[cartItemIndex].favorite = action.result;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartTempArray]));
      return {
        ...state,
        cart: cartTempArray,
        addToFavouriteLoader: false
      };
    case ADD_TO_FAVOURITE_FAILURE:
      return {
        ...state,
        addToFavouriteLoader: false
      };
    case REMOVE_FROM_FAVOURITE_REQUESTED:
      return {
        ...state,
        addToFavouriteLoader: true
      };
    case REMOVE_FROM_FAVOURITE_SUCCESS:
      cartTempArray = [...state.cart];
      cartItemIndex = _.findIndex(cartTempArray, (d) => d.favorite !== null && d.favorite.id === action.payload);
      cartTempArray[cartItemIndex].favorite = null;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartTempArray]));
      return {
        ...state,
        cart: cartTempArray,
        addToFavouriteLoader: false
      };
    case REMOVE_FROM_FAVOURITE_FAILURE:
      return {
        ...state,
        addToFavouriteLoader: false
      };
    case GET_PICKUP_DELIVERY_TIMES_REQUEST:
      return {
        ...state,
        pickUpTime: [],
        pickupDeliveryTimes: [],
        loading: true
      };
    case GET_PICKUP_DELIVERY_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        pickupDeliveryTimes: action.result.pickupTimes,
      };
    case GET_PICKUP_DELIVERY_TIMES_FAILURE:
      return {
        ...state,
        loading: false
      };

    case ADD_TO_SEAT_SUCCESS: {
      const modifiedObject = modifySeatObject(action.payload);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = action.payload ? {
        ...action.payload,
        seats: action.payload.seats ? action.payload.seats.sort((a, b) => { return a.number - b.number; }) : [],
        Items,
        AllItems,
        PendingItems
      } : {};
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      action.resolve(result);
      return {
        ...state,
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
      };
    }
      
    case ADD_TO_SYNC_SUCCESS: {
      const result = action.payload ? {
        ...action.payload
      } : {};
      window.sessionStorage.setItem('syncData', JSON.stringify(result));
      action.resolve(result);
      return {
        ...state,
        syncData: JSON.parse(window.sessionStorage.getItem('syncData')),
      };
    }

    case GET_SEAT_SUCCESS: {
      action.resolve();
      return {
        ...state,
        seats: action.payload,
      };
    }
    case SET_PAGE_NO: {
      window.sessionStorage.setItem('pageNo', action.payload);
      return {
        ...state,
        pageNo: window.sessionStorage.getItem('pageNo')
      };
    }

    case SET_POSITION_NO: {
      const tabPosition = {
        ...action.payload,
        seatId: action.payload?.id
      };
      window.sessionStorage.setItem('position', JSON.stringify(tabPosition));
      action.resolve();
      return {
        ...state,
        position: JSON.parse(window.sessionStorage.getItem('position'))
      };
    }

    case SET_ITEM_TO_CHECK: {
      let item = JSON.parse(window.sessionStorage.getItem('seat'));
      const pos = JSON.parse(window.sessionStorage.getItem('position'));
      const newItem = {
        ...action.payload,
        seats: [{ ...pos, seatId: pos.id, splitAmount: action.payload.price }]
      };
      item = {
        ...item,
        items: [newItem, ...item.items]
      };
      const modifiedObject = modifySeatObject(item);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...item,
        AllItems,
        Items,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        seats: JSON.parse(window.sessionStorage.getItem('seat'))
      };
    }

    case ADD_CHECK_REQUEST:
      return {
        ...state,
        loading: true,
        modifierEditData: null
      };
    case ADD_CHECK_SUCCESS: {
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      window.sessionStorage.setItem('seat', JSON.stringify(action.result));
      return {
        ...state,
        loading: false,
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
        Items,
        AllItems,
        PendingItems
      };
    }
    case ADD_CHECK_FAILURE:
      return {
        ...state,
        loading: false
      };

    case UPDATE_CHECK_REQUEST:
      return {
        ...state,
        loading: true,
        modifierEditData: null,
      };
    case UPDATE_CHECK_SUCCESS: {
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      window.sessionStorage.setItem('seat', JSON.stringify(action.result));
      return {
        ...state,
        loading: false,
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
        Items,
        AllItems,
        PendingItems
      };
    }
    case UPDATE_CHECK_FAILURE:
      return {
        ...state,
        loading: false
      };

    // case MOVE_ITEM_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case MOVE_ITEM_SUCCESS:
    //   window.sessionStorage.setItem('seat', JSON.stringify(action.result));
    //   return {
    //     ...state,
    //     loading: false,
    //     seats: JSON.parse(window.sessionStorage.getItem('seat'))
    //   };
    // case MOVE_ITEM_FAILURE:
    //   return {
    //     ...state,
    //     loading: false
    //   };
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
        loading: true
      };
    // case DELETE_ITEM_SUCCESS:
      // window.sessionStorage.setItem('seat', JSON.stringify(action.result));
      // return {
      //   ...state,
      //   loading: false,
      //   seats: JSON.parse(window.sessionStorage.getItem('seat'))
      // };
    case DELETE_ITEM_SUCCESS: {
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      let cartData = [];
      if (state.cart.length > 0 || JSON.parse(window.sessionStorage.getItem('cart').length > 0)) {
        cartData = state.cart.length > 0 && (JSON.parse(window.sessionStorage.getItem('cart')) || []).filter((v) => {
          return !action.payload.items.some((d) => d.checkOrderItemId === v.id);
        });
        window.sessionStorage.setItem('cart', JSON.stringify(cartData || []));
      }
      const result = {
        ...action.result,
        AllItems,
        Items,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        loading: false,
        cart: cartData || JSON.parse(window.sessionStorage.getItem('cart')),
        cartItemsInfo: _.groupBy(cartData, (d) => d.menuItemId),
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
        highlightedCartItems: [],
      };
    }
    case DELETE_ITEM_FAILURE:
      return {
        ...state,
        loading: false
      };
    case VOID_ITEM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case VOID_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case VOID_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case SEND_CHECKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case SEND_CHECKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        highlightedCartItems: [],
      };
    case SEND_CHECKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case MOVE_CHECKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case MOVE_CHECKS_SUCCESS: {
      if (state.seats) {
        const modifiedObject = modifySeatObject(action.result);
        const Items = modifiedObject.Items;
        const PendingItems = modifiedObject.PendingItems;
        const AllItems = modifiedObject.AllItems;
        const result = {
          ...action.result,
          AllItems,
          Items,
          PendingItems
        };
        window.sessionStorage.setItem('seat', JSON.stringify(result));
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
        highlightedCartItems: [],
      };
    }
    case MOVE_CHECKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case DELETE_ALL_ITEM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_ALL_ITEM_SUCCESS: {
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...action.result,
        AllItems,
        Items,
        PendingItems
      };
      window.sessionStorage.setItem('cart', JSON.stringify([]));
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        loading: false,
        cart: JSON.parse(window.sessionStorage.getItem('cart')),
        cartItemsInfo: {},
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
        highlightedCartItems: [],
      };
    }
    case DELETE_ALL_ITEM_FAILURE:
      return {
        ...state,
        loading: false
      };

    case SEND_ALL_ITEM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SEND_ALL_ITEM_SUCCESS: {
      window.sessionStorage.setItem('cart', JSON.stringify([]));
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...action.result,
        Items,
        AllItems,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        loading: false,
        cart: JSON.parse(window.sessionStorage.getItem('cart')),
        cartItemsInfo: {},
        seats: JSON.parse(window.sessionStorage.getItem('seat'))
      };
    }
    case SEND_ALL_ITEM_FAILURE:
      return {
        ...state,
        loading: false
      };

    case SEND_SINGLE_ITEM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SEND_SINGLE_ITEM_SUCCESS: {
      let cartData = [];
      if (state.cart.length > 0 || JSON.parse(window.sessionStorage.getItem('cart').length > 0)) {
        cartData = state.cart.length > 0 && (JSON.parse(window.sessionStorage.getItem('cart')) || []).filter((v) => {
          return !action.payload.items.some((d) => d.checkOrderItemId === v.id);
        });
        window.sessionStorage.setItem('cart', JSON.stringify(cartData));
      }
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...action.result,
        Items,
        AllItems,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        loading: false,
        cart: JSON.parse(window.sessionStorage.getItem('cart')),
        cartItemsInfo: _.groupBy(cartData, (d) => d.menuItemId),
        seats: JSON.parse(window.sessionStorage.getItem('seat')),
        highlightedCartItems: [],
      };
    }
    case SEND_SINGLE_ITEM_FAILURE:
      return {
        ...state,
        loading: false
      };
    case SEAT_CLEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SEAT_CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        // cart: JSON.parse(window.sessionStorage.getItem('cart')),
        // seats: JSON.parse(window.sessionStorage.getItem('seat'))
      };
    case SEAT_CLEAR_FAILURE:
      return {
        ...state,
        loading: false
      };

    case CLEAR_POSITION_REQUESTED:
      window.sessionStorage.removeItem('position');
      return {
        ...state,
        loading: false,
        position: {}
      };
    case UPDATE_WITH_PAYMENT_STAGES: {
      const previousSeatObj = state.seats || JSON.parse(window.sessionStorage.getItem('seats'));
      const updatedSeat = {
        ...previousSeatObj,
        paymentStages: [...previousSeatObj.paymentStages, ...action.payload],
        splits: [...previousSeatObj.splits, ...action.payload],
      };
      const modifiedObject = modifySeatObject(updatedSeat);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...updatedSeat,
        AllItems,
        Items,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        loading: false,
        seats: updatedSeat
      };
    }
    case REMOVE_WITH_PAYMENT_STAGES: {
      const previousSeatObj = state.seats || JSON.parse(window.sessionStorage.getItem('seats'));
      const updatedSeat = {
        ...previousSeatObj,
        paymentStages: previousSeatObj.paymentStages.filter(
          e => e.id !== action.payload.id
        ),
        splits: previousSeatObj.splits.filter(e => e.id !== action.payload.id),
      };
      const modifiedObject = modifySeatObject(updatedSeat);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...updatedSeat,
        AllItems,
        Items,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        loading: false,
        seats: updatedSeat
      };
    }
    case CLEAR_PAYMENT_CHECK: {
      window.sessionStorage.removeItem('paymentCheck');
      window.sessionStorage.removeItem('tipAmt');
      window.sessionStorage.removeItem('payAmt');
      window.sessionStorage.removeItem('authResponse');
      window.sessionStorage.removeItem('giftCard');
      return {
        ...state,
        loading: false,
        paymentCheck: null
      };
    }
    case SET_PAYMENT_CHECK: {
      window.sessionStorage.setItem('paymentCheck', JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        paymentCheck: JSON.parse(window.sessionStorage.getItem('paymentCheck'))
      };
    }
    case UPDATE_SEAT_INFO: {
      const modifiedObject = modifySeatObject(action.payload);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      const result = {
        ...action.payload,
        AllItems,
        Items,
        PendingItems
      };
      window.sessionStorage.setItem('seat', JSON.stringify(result));
      return {
        ...state,
        seats: action.payload
      };
    }
    case UPDATE_CART_AFTER_NOTE: {
      window.sessionStorage.setItem('cart', JSON.stringify(action.payload));
      return {
        ...state,
        cart: action.payload,
        cartItemsInfo: _.groupBy(action.payload, (d) => d.menuItemId),
      };
    }
    case UPDATE_CHECK_NAME_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CHECK_NAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_CHECK_NAME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SET_HIGHLIGHTED_CART_ITEMS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        highlightedCartItems: action.payload
      };
    }
    case SET_MODIFIER_ID: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        functionModifierId: action.payload
      };
    }
    case CHECK_VOID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CHECK_VOID_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECK_VOID_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_UPSELL_FROM_SESSION:
      action.resolve();
      return {
        ...state,
        upSellItems,
        isUpsellLoading: false
      };

    case SET_STARTOVER_BTN:
      action.resolve();
      return {
        ...state,
        startOverBtn: action.payload
      };

    case SET_CATEGORY_BTN:
      action.resolve();
      return {
        ...state,
        isHeight: action.payload
      };

    case SET_DISABLE_FOOTER:
      action.resolve();
      return {
        ...state,
        isFooter: action.payload
      };

    case SET_STARTOVER_POP_UP:
      action.resolve();
      return {
        ...state,
        startOverPopUp: action.payload
      };
    case SET_GLOBAL_MOD_STATUS:
      action.resolve();
      return {
        ...state,
        globalModEnabled: action.payload
      };
    case SET_SPEED_ORDER_STATUS:
      action.resolve();
      return {
        ...state,
        speedOrderStatus: action.payload
      };
    case SET_SENDMODE_ORDER_STATUS:
      action.resolve();
      return {
        ...state,
        sendModeStatus: action.payload
      };
    case SET_SPEED_ORDER_NAME:
      action.resolve();
      return {
        ...state,
        speedOrderName: action.payload
      };
    case SET_SELECTED_MODIFIER_PATH:
      action.resolve();
      return {
        ...state,
        selectedModifierPath: action.payload
      };
    case PUSH_GLOBAL_MODIFIERS_DATA:
      action.resolve();
      return {
        ...state,
        globalModifiersData: [...state.globalModifiersData, action.payload]
      };
    case CLEAR_GLOBAL_MODIFIERS_DATA:
      action.resolve();
      return {
        ...state,
        globalModifiersData: []
      };
    case GLOBAL_MODIFIER_DATA_REQUESTED:
      return {
        ...state,
        loading: false
      };
    case GLOBAL_MODIFIER_DATA_SUCCESS: {
      const modLinkList = getGlobalModifierLinkData(state, action.isInitial, action.searchAlphabet, action.searchString);
      action.resolve();
      return {
        ...state,
        loading: false,
        globalModifierLinksData: modLinkList
      };
    }
    case SET_GLOBAL_SELECTED_MODIFIER_ID:
      action.resolve();
      return {
        ...state,
        globalSelectedModifierId: action.payload
      };
    case EDIT_MODIFIERS:
      action.resolve();
      return {
        ...state,
        modifierEditData: action.payload
      };
    case SET_COUNTRY_CODE:
      window.sessionStorage.setItem('countryCode', action.payload);
      action.resolve();
      return {
        ...state,
        countrycode: getObjectValue(() => window.sessionStorage.getItem('countryCode'), 1),
      };

    case APPLY_DISCOUNT_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case APPLY_DISCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case APPLY_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case POST_DISCOUNT_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case POST_DISCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case POST_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case DELETE_DISCOUNT_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case DELETE_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case OPEN_COUNTER_DETAILS_POPUP: 
      return {
        ...state,
        openModalPopUp: action.payload,
      };

    case DELETE_ALL_LOCAL_ITEMS: {
      const localItem = JSON.parse(window.sessionStorage.getItem('cart'));
      const cartArray = localItem.filter((e) => ![...action.payload].includes(e.internalId));
      window.sessionStorage.setItem('cart', JSON.stringify(cartArray));
      action.resolve();
      return {
        ...state,
        cart: JSON.parse(window.sessionStorage.getItem('cart'))
        // countrycode: getObjectValue(() => window.sessionStorage.getItem('countryCode'), 1),
      };
    }

    default:
      return state;
  }
};

export const getMenu = (storeId, isCallCenter) => {
  if (window.localStorage.getItem('catchedMenus') && JSON.parse(window.localStorage.getItem('catchedMenus'))[storeId]) {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({
          type: GET_MULTI_STORE_MENU_FROM_SESSION,
          payload: {
            storeId
          },
          resolve
        });
      });
    };
  }
  if (!window.sessionStorage.getItem('menus') || JSON.parse(window.sessionStorage.getItem('storeId')) !== storeId) {
    window.sessionStorage.removeItem('menuTypeId');
    window.sessionStorage.removeItem('scrol');
    window.sessionStorage.removeItem('cart');
    window.sessionStorage.setItem('storeId', storeId);
    const dataToSend = {};
    if (window.sessionStorage.getItem('pickupTime') && window.sessionStorage.getItem('pickupTime') !== 'null') {
      dataToSend.date = `${window.sessionStorage.getItem('pickupDate') ? getObjectValue(() => window.sessionStorage.getItem('pickupDate').split('T')[0], moment().format('YYYY-MM-DD')) : moment().format('YYYY-MM-DD')}T${window.sessionStorage.getItem('pickupTime')}`;
    }
    dataToSend.storeId = storeId;
    dataToSend.jobId = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('userInfo')).jobId, '');
    let deviceInfo = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('deviceInfo')));
    dataToSend.deviceId = deviceInfo?.deviceId;
    dataToSend.deviceGroupId = deviceInfo?.deviceGroup?.id || null;
    if (window.localStorage.getItem('isPreviewMode')) {
      dataToSend.preview = window.localStorage.getItem('isPreviewMode');
      dataToSend.menuId = window.sessionStorage.getItem('MenuId');
    }

    const headers = getHeaders();
    let visibility = deviceType();
    if(visibility==='paymentTerminal'){
      visibility='serverApp'
    }
   
    return {
      types: [GET_MENU_REQUESTED, GET_MENU_SUCCESS, GET_MENU_FAILURE],
      promise: (client) => client.post(isCallCenter ? 'getCallCenterMenu' : `getMenu/${visibility}/order/compressed`, {
        data: dataToSend,
        headers
      })
    };
  }
  return (dispatch) => dispatch({
    type: GET_MENU_FROM_SESSION
  });
};

export const getMultiMenu = (storeId, flowType) => {
  const dataToSend = {};
  if (window.sessionStorage.getItem('pickupTime') && window.sessionStorage.getItem('pickupTime') !== 'null') {
    dataToSend.date = `${window.sessionStorage.getItem('pickupDate') ? getObjectValue(() => window.sessionStorage.getItem('pickupDate').split('T')[0], moment().format('YYYY-MM-DD')) : moment().format('YYYY-MM-DD')}T${window.sessionStorage.getItem('pickupTime')}`;
  }
  dataToSend.storeId = storeId;
  dataToSend.flowType = flowType;
  return {
    types: [GET_MULTI_STORE_MENU_REQUESTED, GET_MULTI_STORE_MENU_SUCCESS, GET_MULTI_STORE_MENU_FAILURE],
    promise: (client) => client.post('getMenu', {
      data: dataToSend
    }),
    payload: dataToSend
  };
};

export const changeMenu = (menuIndex) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_MENU_SUCCESS,
      payload: menuIndex
    });
  };
};

export const onSearchItem = (searchString) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_ITEM_SUCCESS,
      payload: searchString
    });
  };
};

export const pushMenuItemsByScreenId = (screenId) => {
  return (dispatch) => {
    dispatch({
      type: GET_MENU_ITEMS_BY_SCREEN_SUCCESS,
      payload: screenId
    });
  };
};

export const pushModifiersGroupData = (data, isNested) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: PUSH_MODIFIERS_LEVEL_DATA_SUCCESS,
        payload: data,
        condition: isNested
      });
      resolve();
    });
  };
};

export const removeModifiers = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: REMOVE_MODIFIERS_DATA_REQUEST,
        resolve,
        payload: data
      });
    });
  };
};

export const addToCart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TO_CART_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const replaceCart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REPLACE_CART_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const clearCart = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CLEAR_CART_SUCCESS,
        resolve,
        reject
      });
    });
  };
};

export const clearMenu = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CLEAR_MENUS_SUCCESS,
        resolve,
        reject
      });
    });
  };
};

export const clearOrderScreen = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CLEAR_ORDER_SCREEN_SUCCESS,
        resolve,
        reject
      });
    });
  };
};

export const pushModifierToItem = (data) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MODIFIER_SUCCESS,
      payload: data
    });
  };
};

export const updateMultiCart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPADTE_MULTI_CART_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const updateModifierToItemPromise = (path, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPADTE_MODIFIER_PROMISE_SUCCESS,
        payload: data,
        path,
        resolve,
        reject
      });
    });
  };
};

export const appendModifiersToList = (data) => {
  return (dispatch) => {
    dispatch({
      type: APPEND_MODIFIER_SUCCESS,
      payload: data
    });
  };
};

export const pushModifierToCount = (data, isInc, removeLast) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MODIFIER_COUNT_SUCCESS,
      payload: data,
      isInc,
      removeLast
    });
  };
};

export const pushNestedModifierToItem = (data, nestedModifierArray) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_NESTED_MODIFIER_SUCCESS,
      payload: data,
      isNested: nestedModifierArray
    });
  };
};

export const pushCartItemsInfo = () => {
  return (dispatch) => {
    dispatch({
      type: PUSH_CART_ITEMS_INFO_SUCCESS,
    });
  };
};

export const editCartItem = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_CART_ITEM_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const pushCartItems = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: PUSH_CART_ITEMS_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const removeUpSellItems = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_UPSELL_ITEMS_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_UPSELL_ITEMS_SUCCESS,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const pushItemQuantityToCart = (data) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ITEM_QUANTITY_SUCCESS,
      payload: data
    });
  };
};

export const removeItemFromCart = (internalId) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: REMOVE_ITEM_FROM_CART_SUCCESS,
        payload: internalId,
        resolve
      });
    });
  };
};

export const toggleNestedModifierFlag = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS,
      payload: data
    });
  };
};

export const placeOrder = (data) => {
  return {
    types: [PLACE_ORDER_REQUESTED, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE],
    promise: (client) => client.post('placeOrder', {
      data
    })
  };
};

export const orderItems = (data) => {
  return {
    types: [ORDER_ITEM_REQUESTED, ORDER_ITEM_SUCCESS, ORDER_ITEM_FAILURE],
    promise: (client) => client.post('orderItems', {
      data
    })
  };
};

export const priceCheck = (data) => {
  const headers = getHeaders();
  return {
    types: [PLACE_ORDER_REQUESTED, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE],
    promise: (client) => client.post('priceCheck', {
      data,
      headers
    })
  };
};

export const upsellCheck = (data) => {
  const headers = getHeaders();
  if (!window.sessionStorage.getItem('upSell') || (window.sessionStorage.getItem('upSell') && JSON.parse(window.sessionStorage.getItem('upSell')).length === 0) || JSON.parse(window.sessionStorage.getItem('storeId')) !== data.storeId) {
    return {
      types: [UPSELL_REQUESTED, UPSELL_SUCCESS, UPSELL_FAILURE],
      promise: (client) => client.post('upsellCheck', {
        data,
        headers
      }),
      payload: data
    };
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_UPSELL_FROM_SESSION,
        resolve,
        reject
      });
    });
  };
};

export const storePickUpTime = (storeId) => {
  return {
    types: [GET_STORE_PICKUPTIME_REQUESTED, GET_STORE_PICKUPTIME_SUCCESS, GET_STORE_PICKUPTIME_FAILURE],
    promise: (client) => client.get(`getStorePickUpTime/${storeId}`),
  };
};

export const storePickUpTimeByDate = (storeId, date) => {
  return {
    types: [GET_STORE_PICKUPTIMEBYDATE_REQUESTED, GET_STORE_PICKUPTIMEBYDATE_SUCCESS, GET_STORE_PICKUPTIMEBYDATE_FAILURE],
    promise: (client) => client.get(`getStorePickUpTime/${storeId}/date/${date}`),
  };
};

export const storeDeliveryTime = (storeId) => {
  return {
    types: [GET_STORE_DELIVERYTIME_REQUESTED, GET_STORE_DELIVERYTIME_SUCCESS, GET_STORE_DELIVERYTIME_FAILURE],
    promise: (client) => client.get(`getStoreDeliveryTime/${storeId}`),
  };
};

export const storeDeliveryTimeByDate = (storeId, date) => {
  return {
    types: [GET_STORE_DELIVERYTIMEBYDATE_REQUESTED, GET_STORE_DELIVERYTIMEBYDATE_SUCCESS, GET_STORE_DELIVERYTIMEBYDATE_FAILURE],
    promise: (client) => client.get(`getStoreDeliveryTime/${storeId}/date/${date}`),
  };
};

export const setMenuIndex = (menuIndex) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_INDEX,
      payload: menuIndex
    });
  };
};

export const storePickUpDeliveryTime = (storeId) => {
  return {
    types: [GET_STORE_PICKUPDELIVERYTIME_REQUESTED, GET_STORE_PICKUPDELIVERYTIME_SUCCESS, GET_STORE_PICKUPDELIVERYTIME_FAILURE],
    promise: (client) => client.get(`getStorePickupDeliveryTime/${storeId}`),
  };
};

export const storePickUpTimeBasedOnDate = (storeId, date) => {
  return {
    types: [GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED, GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS, GET_STORE_PICKUPTIME_DATE_BASED_FAILURE],
    promise: (client) => client.get(`getStorePickUpTimeBasedOnDate/${storeId}/date/${date}`),
  };
};

export const getOtherOrdersList = (data) => {
  return {
    types: [GET_OTHER_ORDERS_REQUEST, GET_OTHER_ORDERS_SUCCESS, GET_OTHER_ORDERS_FAILURE],
    promise: (client) => client.post('daisyChain', {
      data
    }),
  };
};

export const updateCollapsedModifierArray = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS,
      payload: data
    });
  };
};

export const handleAddFavourite = (data) => {
  const dataToSend = {
    menuItemId: data.menuItemId,
    orderItemJson: JSON.stringify(data)
  };
  return {
    types: [ADD_TO_FAVOURITE_REQUESTED, ADD_TO_FAVOURITE_SUCCESS, ADD_TO_FAVOURITE_FAILURE],
    promise: (client) => client.post('addFavourite', {
      data: dataToSend
    }),
    payload: data
  };
};

export const handleRemoveFavourite = (data) => {
  return {
    types: [REMOVE_FROM_FAVOURITE_REQUESTED, REMOVE_FROM_FAVOURITE_SUCCESS, REMOVE_FROM_FAVOURITE_FAILURE],
    promise: (client) => client.post(`removeFavourite/${data}`),
    payload: data
  };
};

export const getPickupDeliveryTimes = (data) => {
  return {
    types: [GET_PICKUP_DELIVERY_TIMES_REQUEST, GET_PICKUP_DELIVERY_TIMES_SUCCESS, GET_PICKUP_DELIVERY_TIMES_FAILURE],
    promise: (client) => client.post('getPickDeliveryTimes', {
      data
    })
  };
};

export const setSeat = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TO_SEAT_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const setSyncData = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TO_SYNC_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const getSeat = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_SEAT_SUCCESS,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const renameSeat = (data) => {
  const headers = getHeaders();
  return {
    types: [RENAME_SEAT_REQUEST, RENAME_SEAT_SUCCESS, RENAME_SEAT_FAILURE],
    promise: (client) => client.post('renameSeat', {
      data,
      headers,
    }),
  };
};

export const setCurrentPage = (pageNo) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_PAGE_NO,
        payload: pageNo,
        resolve,
        reject
      });
    });
  };
};

export const setPosition = (seat) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_POSITION_NO,
        payload: seat,
        reject,
        resolve
      });
    });
  };
};

export const addToChecks = (data) => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_ITEM_TO_CHECK,
        payload: data,
        resolve,
        reject
      });
    });
  };
};

export const removeSeat = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEAT_SUCCESS,
    });
  };
};

export const addChecks = (data, identifier) => {
  const headers = getHeaders();
  if (identifier === 'add') {
    return {
      types: [ADD_CHECK_REQUEST, ADD_CHECK_SUCCESS, ADD_CHECK_FAILURE],
      promise: (client) => client.post('addToCheck', {
        data,
        headers,
      }),
    };
  }
  return {
    types: [UPDATE_CHECK_REQUEST, UPDATE_CHECK_SUCCESS, UPDATE_CHECK_FAILURE],
    promise: (client) => client.post('updateCheck', {
      data,
      headers
    })
  };
};

export const deleteItm = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS, DELETE_ITEM_FAILURE],
    promise: (client) => client.post('deleteItem', {
      data,
      headers,
    }),
    payload: data
  };
};

export const voidItm = (data) => {
  const headers = getHeaders();
  return {
    types: [VOID_ITEM_REQUEST, VOID_ITEM_SUCCESS, VOID_ITEM_FAILURE],
    promise: (client) => client.post('voidItem', {
      data,
      headers,
    }),
  };
};

export const voidCheck = (data) => {
  const headers = getHeaders();
  return {
    types: [VOID_CHECK_REQUEST, VOID_CHECK_SUCCESS, VOID_CHECK_FAILURE],
    promise: (client) => client.post('voidCheck', {
      data,
      headers,
    }),
  };
};

export const deleteAllItems = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_ALL_ITEM_REQUEST, DELETE_ALL_ITEM_SUCCESS, DELETE_ALL_ITEM_FAILURE],
    promise: (client) => client.post('deleteItem', {
      data,
      headers,
    }),
  };
};

export const sendChecks = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_CHECKS_REQUEST, SEND_CHECKS_SUCCESS, SEND_CHECKS_FAILURE],
    promise: (client) => client.post('sendChecks', {
      data,
      headers
    })
  };
};

export const moveChecks = (data) => {
  const headers = getHeaders();
  return {
    types: [MOVE_CHECKS_REQUEST, MOVE_CHECKS_SUCCESS, MOVE_CHECKS_FAILURE],
    promise: (client) => client.post('moveChecks', {
      data,
      headers
    })
  };
};

export const sendAllItem = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_ALL_ITEM_REQUEST, SEND_ALL_ITEM_SUCCESS, SEND_ALL_ITEM_FAILURE],
    promise: (client) => client.post('sendChecks', {
      data,
      headers,
    }),
  };
};

export const singleSend = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_SINGLE_ITEM_REQUEST, SEND_SINGLE_ITEM_SUCCESS, SEND_SINGLE_ITEM_FAILURE],
    promise: (client) => client.post('sendChecks', {
      data,
      headers,
    }),
    payload: data
  };
};

export const seatClear = (data) => {
  const headers = getHeaders();
  return {
    types: [SEAT_CLEAR_REQUEST, SEAT_CLEAR_SUCCESS, SEAT_CLEAR_FAILURE],
    promise: (client) => client.post('seatClear', {
      data,
      headers,
    }),
    payload: data
  };
};

export const removePosition = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_POSITION_REQUESTED
    });
  };
};

export const updateWithPaymentStages = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_WITH_PAYMENT_STAGES,
        payload: data
      });
      resolve();
    });
  };
};

export const removeWithPaymentStages = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: REMOVE_WITH_PAYMENT_STAGES,
        payload: data
      });
      resolve();
    });
  };
};

export const removePaymentCheck = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: CLEAR_PAYMENT_CHECK,
      });
      resolve();
    });
  };
};

export const setPaymentCheck = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_PAYMENT_CHECK,
        payload: data,
      });
      resolve();
    });
  };
};

export const updateSeatInfo = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_SEAT_INFO,
        payload: data
      });
      resolve();
    });
  };
};

export const updateCartAfterNote = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CART_AFTER_NOTE,
      payload: data
    });
  };
};

export const updateCheckName = (data) => {
  const headers = getHeaders();
  return {
    types: [UPDATE_CHECK_NAME_REQUEST, UPDATE_CHECK_NAME_SUCCESS, UPDATE_CHECK_NAME_FAILURE],
    promise: (client) => client.post('updateCheckName', {
      data,
      headers,
    }),
    payload: data
  };
};

export const setHighlightCartItems = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_HIGHLIGHTED_CART_ITEMS,
        payload: data
      }
    );
  };
};

export const setFunctionModifierId = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_MODIFIER_ID,
        payload: data
      }
    );
  };
};

export const checkVoid = (data) => {
  const headers = getHeaders();
  return {
    types: [CHECK_VOID_REQUEST, CHECK_VOID_SUCCESS, CHECK_VOID_FAILURE],
    promise: (client) => client.post('checkVoid', {
      data,
      headers,
    }),
  };
};

export const enableStartOverBtn = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_STARTOVER_BTN,
        resolve,
        payload: data
      });
    });
  };
};

export const setCategoryHeight = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_CATEGORY_BTN,
        resolve,
        payload: data
      });
    });
  };
};

export const disableFooter = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_DISABLE_FOOTER,
        resolve,
        payload: data
      });
    });
  };
};

export const openStartOrderPopUp = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_STARTOVER_POP_UP,
        resolve,
        payload: data
      });
    });
  };
};

export const getOrderMenus = (storeId) => {
  const headers = getHeaders();
  const visibility = deviceType();
  return {
    types: [GET_MENU_REQUESTED, GET_MENU_SUCCESS, GET_MENU_FAILURE],
    promise: (client) => client.post(`getMenu/${visibility}/order/compressed`, {
      data: {
        storeId
      },
      headers
    })
  };
};

export const applyDiscount = (data) => {
  const headers = getHeaders();
  return {
    types: [APPLY_DISCOUNT_REQUESTED, APPLY_DISCOUNT_SUCCESS, APPLY_DISCOUNT_FAILURE],
    promise: (client) => client.post('applyDiscount', {
      data,
      headers
    })
  };
};

export const deleteDiscount = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_DISCOUNT_REQUESTED, DELETE_DISCOUNT_SUCCESS, DELETE_DISCOUNT_FAILURE],
    promise: (client) => client.post('deleteDiscount', {data, headers})
  };
};

export const postDiscount = (data) => {
  const headers = getHeaders();
  return {
    types: [POST_DISCOUNT_REQUESTED, POST_DISCOUNT_SUCCESS, POST_DISCOUNT_FAILURE],
    promise: (client) => client.post('postDiscount', {
      data,
      headers
    })
  };
};

export const setGlobalModStatus = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_GLOBAL_MOD_STATUS,
        resolve,
        payload: data
      });
    });
  };
};
export const setSpeedOrderStatus = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SPEED_ORDER_STATUS,
        resolve,
        payload: data
      });
    });
  };
};
export const setSendModeStatus = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SENDMODE_ORDER_STATUS,
        resolve,
        payload: data
      });
    });
  };
};
export const setSpeedOrderName = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SPEED_ORDER_NAME,
        resolve,
        payload: data
      });
    });
  };
};

export const setSelectedModifierPath = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SELECTED_MODIFIER_PATH,
        resolve,
        payload: data
      });
    });
  };
};

export const pushGlobalModifiersData = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: PUSH_GLOBAL_MODIFIERS_DATA,
        resolve,
        payload: data
      });
    });
  };
};

export const clearGlobalModifiersData = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: CLEAR_GLOBAL_MODIFIERS_DATA,
        resolve,
        payload: data
      });
    });
  };
};

export const getGlobalModifierData = (isInitial, searchAlphabet, searchString) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: GLOBAL_MODIFIER_DATA_SUCCESS,
        isInitial,
        searchAlphabet,
        searchString,
        resolve,
        reject
      });
    });
  };
};

export const setGlobalSelectedModifierId = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_GLOBAL_SELECTED_MODIFIER_ID,
        resolve,
        payload: data
      });
    });
  };
};

export const editModifiers = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: EDIT_MODIFIERS,
        resolve,
        payload: data
      });
    });
  };
};

export const setCountrycode = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_COUNTRY_CODE,
        resolve,
        payload: data
      });
    });
  };
};

export const deleteAllLocalItems = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: DELETE_ALL_LOCAL_ITEMS,
        resolve,
        payload: data
      });
    });
  };
};

export const searchString = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_STRING,
      payload: data
    });
  };
};
export const getUpdatedCart = () => {
  return getObjectValue(() => store.getState().orders.cart, []);
};

export const getSyncModifierData = () => {
  return getObjectValue(() => store.getState().orders.modifierData, []);
};

export const deleteCheckDiscount = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_DISCOUNT_REQUESTED, DELETE_DISCOUNT_SUCCESS, DELETE_DISCOUNT_FAILURE],
    promise: (client) => client.post('deleteCheckDiscount', {data, headers})
  };
};

export const setOpenModalPopUp = (data) => {
  return (dispatch) => {
    dispatch(({
      type: OPEN_COUNTER_DETAILS_POPUP,
      payload: data
    }))
  }
}
