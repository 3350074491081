// import Ringtone from '@assets/tones/got-it-done-613.mp3';
import EventEmitter from '@utility/eventSubscribers';

export const SET_NOTIFICATION = 'notification/SET_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'notification/CLOSE_NOTIFICATION';
export const SET_NOTIFICATION_ALARM = 'notification/SET_NOTIFICATION_ALARM';
export const CLOSE_NOTIFICATION_ALARM = 'notification/CLOSE_NOTIFICATION_ALARM';

export const SET_ERROR_POPUP = 'notification/SET_ERROR_POPUP';
export const CLOSE_ERROR_POPUP = 'notification/CLOSE_ERROR_POPUP';
export const CLOSE_PRINT_SUCCESS_POP_UP = 'notification/CLOSE_PRINT_SUCCESS_POP_UP';

export const SET_PRINT_NOTIFICATION = 'notification/SET_PRINT_NOTIFICATION';
export const CLOSE_PRINT_NOTIFICATION = 'notification/CLOSE_PRINT_NOTIFICATION';

export const STOP_NOTIFICATION_ALARM = 'notification/STOP_NOTIFICATION_ALARM';

export const SHOW_SUCCESS_CHECK_MARK = "notification/SHOW_SUCCESS_CHECK_MARK";
export const NOT_SHOW_SUCCESS_CHECK_MARK = "notification/NOT_SHOW_SUCCESS_CHECK_MARK";

export const PRINT_NOTIFICATION_CLOSE = "notification/PRINT_NOTIFICATION_CLOSE";

const initialValues = {
  isOpen: false,
  isErrorPopUpOpen: false,
  isPrintOpen: false,
  isPrintSuccessOpen: false,
  message: '',
  customClass: '',
  printData: '',
  showSuccessCheckMark: false,
};
// const audioElement = new Audio(Ringtone);

export default (state = initialValues, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        type: action.payload.type,
        isNotificationAlarm: false,
      };
    case SET_NOTIFICATION_ALARM: {
      // const stopSound = JSON.parse(window.sessionStorage.getItem('stopSound'));
      // audioElement.loop = true;
      // audioElement.currentTime = 0;
      // if (stopSound) {
      //   audioElement.play();
      // }
      if (window.ReactNativeWebView) EventEmitter.playAudio(true);
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        type: action.payload.type,
        isNotificationAlarm: true,
      };
    }
    case CLOSE_NOTIFICATION: {
      return {
        ...state,
        isOpen: false
      };
    }
    case CLOSE_NOTIFICATION_ALARM: {
      // audioElement.pause();
      if (window.ReactNativeWebView) EventEmitter.playAudio(false);
      return {
        ...state,
        isOpen: false
      };
    }

    case STOP_NOTIFICATION_ALARM: {
      // window.sessionStorage.setItem('stopSound', false);
      // audioElement.pause();
      if (window.ReactNativeWebView) EventEmitter.playAudio(false);
      return {
        ...state
      };
    }

    case SET_PRINT_NOTIFICATION:
      return {
        ...state,
        isPrintOpen: true,
        printData: action.payload
      };

    case CLOSE_PRINT_NOTIFICATION: {
      return {
        ...state,
        isPrintOpen: false,
        isPrintSuccessOpen: true,
      };
    }
    case PRINT_NOTIFICATION_CLOSE: {
      return {
        ...state,
        isPrintSuccessOpen: false,
      };
    }
     case SET_ERROR_POPUP: {
      return {
        ...state,
        isErrorPopUpOpen: true,
        res: action.payload.res
      };
     } 
     case CLOSE_ERROR_POPUP: {
        return {
          ...state,
          isErrorPopUpOpen: false
        };
      }
     case CLOSE_PRINT_SUCCESS_POP_UP: {
        return {
          ...state,
          isPrintSuccessOpen: false,
        }
      }
      case SHOW_SUCCESS_CHECK_MARK : {
        return {
          ...state, 
          showSuccessCheckMark: true,
          duration: action.payload?.duration,
        }
      }
      case NOT_SHOW_SUCCESS_CHECK_MARK: {
        return {
          ...state,
          showSuccessCheckMark: false,
        }
      }
    default:
      return state;
  }
};

export const setNotification = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_NOTIFICATION,
          payload: data
        }
      );
      const id = setTimeout(() => {
        dispatch({
          type: CLOSE_NOTIFICATION
        });
        clearTimeout(id);
        resolve();
      }, 3000);
    });
  };
};

export const closeNotification = () => {
  return (dispatch) => {
    dispatch(
      {
        type: CLOSE_NOTIFICATION,
      }
    );
  };
};

export const stopNotificationSound = () => {
  return (dispatch) => {
    dispatch(
      {
        type: STOP_NOTIFICATION_ALARM,
      }
    );
  };
};

export const setPrintNotification = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_PRINT_NOTIFICATION,
        payload: data
      }
    );
    setTimeout(() => dispatch({
      type: CLOSE_PRINT_NOTIFICATION
    }), 5000);
  };
};

export const closePrintNotification = () => {
  return (dispatch) => {
    dispatch(
      {
        type: CLOSE_PRINT_NOTIFICATION,
        // payload: data
      }
    );
  };
};

export const setErrorPopUp = (data) => {
     return (dispatch) => {
      dispatch(
        {
          type: SET_ERROR_POPUP,
          payload: data
        }
      )
     }
};
export const closeErrorPopUp = () => {
  return (dispatch) => {
   dispatch(
     {
       type: CLOSE_ERROR_POPUP,
     }
   )
  }
};

export const closePrintSuccessPopUp = () => {
  return (dispatch) => {
    dispatch(
      {
        type: CLOSE_PRINT_SUCCESS_POP_UP
      }
    )
  }
}

export const showSuccessCheckMark = (duration) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SHOW_SUCCESS_CHECK_MARK,
          payload: duration,
        }
      );
      
      const timerId = setTimeout(() => {
        dispatch({
          type: NOT_SHOW_SUCCESS_CHECK_MARK,
        });
        clearTimeout(timerId);
        resolve();
      }, duration || 100)
    })
  }
}

export const closePrint = () => {
  return (dispatch) => {
    dispatch(
      {
        type: PRINT_NOTIFICATION_CLOSE
      }
    )
  }
}