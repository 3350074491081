import { arrayOperations } from '@utility';
import { getHeaders } from '@utility/helperFunction';
import { getObjectValue } from '@utility/utills';
import constants from '@components/Fireboard/components/Header/constants';

export const OAUTH_REQUESTED = 'oauth/OAUTH_REQUESTED';
export const OAUTH_SUCCESS = 'oauth/OAUTH_SUCCESS';
export const OAUTH_FAILURE = 'oauth/OAUTH_FAILURE';

export const STORE_INFO_REQUESTED = 'oauth/STORE_INFO_REQUESTED';
export const STORE_INFO_SUCCESS = 'oauth/STORE_INFO_SUCCESS';
export const SET_STORE_INFO_FROM_RN = 'oauth/SET_STORE_INFO_FROM_RN'
export const SET_DEVICE_INFO_FROM_RN = 'oauth/SET_DEVICE_INFO_FROM_RN'
export const STORE_INFO_FAILURE = 'oauth/STORE_INFO_FAILURE';

export const OAUTH_LOGIN_SUCCESS = 'oauth/OAUTH_LOGIN_SUCCESS';
export const OAUTH_MANAGER_LOGIN_SUCCESS = 'oauth/OAUTH_MANAGER_LOGIN_SUCCESS';
export const OAUTH_LOGIN_REQUESTED = 'oauth/OAUTH_LOGIN_REQUESTED';
export const OAUTH_LOGIN_FAILURE = 'oauth/OAUTH_LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'oauth/LOGOUT_SUCCESS';
export const LOGOUT_REQUESTED = 'oauth/LOGOUT_REQUESTED';
export const LOGOUT_FAILURE = 'oauth/LOGOUT_FAILURE';

export const JOB_CHANGE_SUCCESS = 'oauth/JOB_CHANGE_SUCCESS';
export const JOB_CHANGE_REQUESTED = 'oauth/JOB_CHANGE_REQUESTED';
export const JOB_CHANGE_FAILURE = 'oauth/JOB_CHANGE_FAILURE';

export const ORIENTATION_CHANGE_SUCCESS = 'oauth/ORIENTATION_CHANGE_SUCCESS';
export const ORIENTATION_CHANGE_REQUESTED = 'oauth/ORIENTATION_CHANGE_REQUESTED';
export const ORIENTATION_CHANGE_FAILURE = 'oauth/ORIENTATION_CHANGE_FAILURE';

export const GET_NATIVE_DETAILS = 'oauth/GET_NATIVE_DETAILS';

export const OPEN_EMPLOYEE_CHECK = 'oauth/OPEN_EMPLOYEE_CHECK';
export const CLOSE_EMPLOYEE_CHECK = 'oauth/CLOSE_EMPLOYEE_CHECK';

export const OPEN_MANAGER_CHECK = 'oauth/OPEN_MANAGER_CHECK';
export const CLOSE_MANAGER_CHECK = 'oauth/CLOSE_MANAGER_CHECK';

export const SET_DEVICE_TYPE_ID = 'oauth/SET_DEVICE_TYPE_ID';

export const SET_CARD_READER_STATUS = 'oauth/SET_CARD_READER_STATUS';
export const REMOVE_CARD_READER_STATUS = 'oauth/REMOVE_CARD_READER_STATUS';

export const SET_INACTIVITY_STATUS = 'oauth/SET_INACTIVITY_STATUS';
export const SET_SCREENSAVER_STATUS = 'oauth/SET_SCREENSAVER_STATUS';
export const SET_SOCKET_CONNECTION = 'oauth/SET_SOCKET_CONNECTION';

export const SET_INACTIVITY_POPUP_STATUS = 'oauth/SET_INACTIVITY_POPUP_STATUS';

export const SET_FIREBOARD_CURRENT_VIEW = 'fireboard/SET_FIREBOARD_CURRENT_VIEW';

export const SET_FIREBOARD_CURRENT_MODE = 'fireboard/SET_FIREBOARD_CURRENT_MODE';

const createEmpJobHashTable = (auth) => {
  const job = {};
  if (auth) {
    auth.jobs.forEach((item) => {
      // const employees = auth.employees.filter((e) => e.jobId === item.id);
      const employees = {};
      auth.employees.forEach((e) => {
        if (e.jobId === item.id) {
          employees[e.id] = e;
        }
      });
      job[item.id] = { ...item, employees };
    });
  }
  return job;
};

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userdata: null,
  storeInfo: null,
  loggedInUser: window.sessionStorage.getItem('isUserLogin'),
  deviceInformation: (JSON.parse(window.sessionStorage.getItem('deviceInfo'))),
  authInfo: (JSON.parse(window.sessionStorage.getItem('auth'))),
  loggedInUserData: JSON.parse(window.sessionStorage.getItem('userInfo')),
  nativeAppVersion: '',
  environment: '',
  employeeHashTable: arrayOperations.listToHashTable(getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).employees, [])),
  employeeJobHashTable: createEmpJobHashTable(getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')), [])),
  isEmployeeCheck: false,
  isManagerCheck: false,
  deviceTypeId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('deviceInfo')).deviceTypeId, 7),
  cardReaderStatus: getObjectValue(() => window.sessionStorage.getItem('cardReaderStatus'), false),
  inActivityStatus: false,
  socketConnected: false,
  isOpen: false,
  screenSaverStatus: false,
  currentView: constants.otherViewTypes.find(option => option.id === JSON.parse(window.sessionStorage.getItem('deviceInfo') || '{}')?.deviceGroup?.fireBoardView)?.type || null,
  currentMode: constants.otherModeTypes.find(option => option.id === JSON.parse(window.sessionStorage.getItem('deviceInfo') || '{}')?.deviceGroup?.fireBoardMode)?.type || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OAUTH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OAUTH_SUCCESS: {
      window.sessionStorage.setItem('deviceInfo', JSON.stringify({ ...action.result }));
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result,
        deviceInformation: (JSON.parse(window.sessionStorage.getItem('deviceInfo')))
      };
    }
    case SET_DEVICE_INFO_FROM_RN: {
      window.sessionStorage.setItem('deviceInfo', JSON.stringify({...action.payload}));
      return {
        ...state,
        loading: true,
        loaded: false,
        userdata: action.payload,
        deviceInformation: (JSON.parse(window.sessionStorage.getItem('deviceInfo')))
      };
    }
    case OAUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case JOB_CHANGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case JOB_CHANGE_SUCCESS: {
      window.sessionStorage.setItem('userInfo', JSON.stringify({ ...action.result, flipView: state.deviceTypeId === 3 ? !(state.deviceTypeId === 3) : action.result.flipView }));
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result,
        loggedInUserData: JSON.parse(window.sessionStorage.getItem('userInfo'))
      };
    }
    case JOB_CHANGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case ORIENTATION_CHANGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ORIENTATION_CHANGE_SUCCESS: {
      window.sessionStorage.setItem('userInfo', JSON.stringify(action.result));
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result,
        loggedInUserData: JSON.parse(window.sessionStorage.getItem('userInfo'))
      };
    }
    case ORIENTATION_CHANGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LOGOUT_REQUESTED: {
      window.sessionStorage.removeItem('position');
      window.sessionStorage.removeItem('seat');
      window.sessionStorage.removeItem('cart');
      window.sessionStorage.removeItem('userInfo');
      window.sessionStorage.removeItem('isUserLogin');
      window.sessionStorage.removeItem('payMultiCart');
      window.sessionStorage.removeItem('storeId');
      window.sessionStorage.removeItem('menus');
      window.sessionStorage.removeItem('menuName');
      window.sessionStorage.removeItem('menuScreen');
      window.sessionStorage.removeItem('pageNo');
      window.sessionStorage.removeItem('dissmissableFooter');
      window.sessionStorage.removeItem('menuTypeId');
      window.sessionStorage.removeItem('open');
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loggedInUserData: window.sessionStorage.getItem('userInfo')
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case OAUTH_LOGIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OAUTH_LOGIN_SUCCESS: {
      window.sessionStorage.setItem('userInfo', JSON.stringify({ ...action.result, employeeCard: action.payload.employeeCard || null, flipView: state.deviceTypeId === 3 ? !(state.deviceTypeId === 3) : action.result.flipView }));
      window.sessionStorage.setItem('isUserLogin', true);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loggedInUser: window.sessionStorage.getItem('isUserLogin'),
        loggedInUserData: JSON.parse(window.sessionStorage.getItem('userInfo'))
      };
    }
    case OAUTH_MANAGER_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loggedInUser: window.sessionStorage.getItem('isUserLogin'),
        loggedInUserData: JSON.parse(window.sessionStorage.getItem('userInfo'))
      };
    }
    case OAUTH_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case STORE_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case STORE_INFO_SUCCESS: {
      window.sessionStorage.setItem('auth', JSON.stringify(action.result));
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        storeInfo: action.result,
        authInfo: (JSON.parse(window.sessionStorage.getItem('auth'))),
        employeeHashTable: arrayOperations.listToHashTable(getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).employees, [])),
        employeeJobHashTable: createEmpJobHashTable(getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')), []))
      };
    }
    case SET_STORE_INFO_FROM_RN: {
      window.sessionStorage.setItem('auth', JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        storeInfo: action.payload,
        authInfo: (JSON.parse(window.sessionStorage.getItem('auth'))),
        employeeHashTable: arrayOperations.listToHashTable(getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).employees, [])),
        employeeJobHashTable: createEmpJobHashTable(getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')), []))
      };
    }
    case STORE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_NATIVE_DETAILS: {
      return {
        ...state,
        loading: false,
        nativeAppVersion: action.payload.appVersion,
        environment: action.payload.environment
      };
    }
    case OPEN_EMPLOYEE_CHECK: {
      return {
        ...state,
        loading: false,
        isEmployeeCheck: true
      };
    }
    case CLOSE_EMPLOYEE_CHECK: {
      return {
        ...state,
        loading: false,
        isEmployeeCheck: false
      };
    }
    case OPEN_MANAGER_CHECK: {
      return {
        ...state,
        loading: false,
        isManagerCheck: true
      };
    }
    case CLOSE_MANAGER_CHECK: {
      return {
        ...state,
        loading: false,
        isManagerCheck: false
      };
    }
    case SET_DEVICE_TYPE_ID: {
      window.sessionStorage.setItem('deviceInfo', JSON.stringify({ ...state.deviceInformation, deviceTypeId: Number(action.payload) }));
      return {
        ...state,
        loading: false,
        deviceTypeId: JSON.parse(window.sessionStorage.getItem('deviceInfo')).deviceTypeId,
        deviceInformation: (JSON.parse(window.sessionStorage.getItem('deviceInfo'))),
        currentView: constants.otherViewTypes.find(option => option.id === JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceGroup?.fireBoardView)?.type || 'listView',
        currentMode: constants.otherModeTypes.find(option => option.id === JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceGroup?.fireBoardMode)?.type || 'DEFAULT',

      };
    }
    case SET_CARD_READER_STATUS: {
      window.sessionStorage.setItem('cardReaderStatus', action.payload);
      return {
        ...state,
        loading: false,
        cardReaderStatus: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('cardReaderStatus')), false)
      };
    }
    case REMOVE_CARD_READER_STATUS: {
      window.sessionStorage.removeItem('cardReaderStatus');
      return {
        ...state,
        loading: false,
        cardReaderStatus: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('cardReaderStatus')), false)
      };
    }
    case SET_INACTIVITY_STATUS: {
      return {
        ...state,
        inActivityStatus: action.payload
      };
    }
    case SET_SCREENSAVER_STATUS: {
      return {
        ...state,
        screenSaverStatus: action.payload
      };
    }
    case SET_SOCKET_CONNECTION: {
      window.sessionStorage.setItem('socketConnected', action.payload);
      return {
        ...state,
        socketConnected: JSON.parse(window.sessionStorage.getItem('socketConnected'))
      };
    }

    case SET_INACTIVITY_POPUP_STATUS: {
      return {
        ...state,
        isOpen: action.payload
      };
    }
    case SET_FIREBOARD_CURRENT_VIEW: {
      return {
        ...state,
        currentView: action.payload
      };
    }
    case SET_FIREBOARD_CURRENT_MODE: {
      return {
        ...state,
        currentMode: action.payload
      };
    }
    default:
      return state;
  }
};

export const activation = (data) => {
  return {
    types: [OAUTH_REQUESTED, OAUTH_SUCCESS, OAUTH_FAILURE],
    promise: (client) => client.post('activate', {
      data
    })
  };
};

export const setdeviceInfoFromRN = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_DEVICE_INFO_FROM_RN,
          payload: data
        }
      );
      resolve();
    });
  };
};

export const storeInfo = (headersResponse, deviceId) => {
  return {
    types: [STORE_INFO_REQUESTED, STORE_INFO_SUCCESS, STORE_INFO_FAILURE],
    promise: (client) => client.get(`storeInfo/${deviceId}`, { headers: headersResponse })
  };
};

export const setStoreInfoFromRN = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_STORE_INFO_FROM_RN,
          payload: data
        }
      );
      resolve();
    });
  };
};

export const login = (data, managerCheck) => {
  
  const headers = getHeaders();
  return {
    types: [OAUTH_LOGIN_REQUESTED, managerCheck ? OAUTH_MANAGER_LOGIN_SUCCESS :OAUTH_LOGIN_SUCCESS, OAUTH_LOGIN_FAILURE],
    promise: (client) => client.post('login', {
      data,
      headers
    }),
    payload: data
  };
};

export const logout = (data) => {
  const headers = getHeaders();
  return {
    types: [LOGOUT_REQUESTED, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    promise: (client) => client.post('logout', {
      data,
      headers
    })
  };
};

export const appDetails = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: GET_NATIVE_DETAILS,
        payload: data
      }
    );
  };
};

export const changeJob = (data) => {
  const headers = getHeaders();
  return {
    types: [JOB_CHANGE_REQUESTED, JOB_CHANGE_SUCCESS, JOB_CHANGE_FAILURE],
    promise: (client) => client.post('changeJob', {
      data,
      headers
    })
  };
};

export const orientationChange = (data) => {
  const headers = getHeaders();
  return {
    types: [ORIENTATION_CHANGE_REQUESTED, ORIENTATION_CHANGE_SUCCESS, ORIENTATION_CHANGE_FAILURE],
    promise: (client) => client.post('orientationChange', {
      data,
      headers
    })
  };
};

export const openEmployeeCheck = () => {
  return (dispatch) => {
    dispatch(
      {
        type: OPEN_EMPLOYEE_CHECK,
      }
    );
  };
};

export const closeEmployeeCheck = () => {
  return (dispatch) => {
    dispatch(
      {
        type: CLOSE_EMPLOYEE_CHECK,
      }
    );
  };
};

export const openManagerCheck = () => {
  return (dispatch) => {
    dispatch(
      {
        type: OPEN_MANAGER_CHECK,
      }
    );
  };
};

export const closeManagerCheck = () => {
  return (dispatch) => {
    dispatch(
      {
        type: CLOSE_MANAGER_CHECK,
      }
    );
  };
};

export const setDeviceTypeId = (id) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_DEVICE_TYPE_ID,
          payload: id
        }
      );
      resolve();
    });
  };
};

export const setCardReaderStatus = (status) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_CARD_READER_STATUS,
          payload: status
        }
      );
      resolve();
    });
  };
};

export const clearCardReader = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: REMOVE_CARD_READER_STATUS,
        }
      );
      resolve();
    });
  };
};

export const setInActivityStatus = (status) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_INACTIVITY_STATUS,
          payload: status
        }
      );
      resolve();
    });
  };
};

export const setScreenSaverStatus = (status) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_SCREENSAVER_STATUS,
          payload: status
        }
      );
      resolve();
    });
  };
};

export const setOpen = (status) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(
        {
          type: SET_INACTIVITY_POPUP_STATUS,
          payload: status
        }
      );
      resolve();
    });
  };
};

export const setSocketConnection = (payload) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_SOCKET_CONNECTION,
        payload
      }
    );
  };
};

export const setCurrentView = (data) => {
  return {
    type: SET_FIREBOARD_CURRENT_VIEW,
    payload: data,
  };
};


export const setCurrentMode = (data) => {
  return {
    type: SET_FIREBOARD_CURRENT_MODE,
    payload: data,
  };
};